import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client/react/context/ApolloProvider";
import ReactGA from 'react-ga';

//! Ant Imports

import Button from "antd/lib/button";
import message from "antd/lib/message";

//! User Files

import client from "./apollo";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import { AppContextProvider } from "./AppContext";

//! Styles

import "./index.css";
import "./styles/main.less";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <Routes />
    </AppContextProvider>
  </ApolloProvider>,
  // eslint-disable-next-line no-undef
  document.getElementById("root")
);

// Do this once
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      message.info({
        content: (
          <>
            New version available! Click Reload to get the latest version.
            <Button
              className="ml-1 mb-0"
              type="link"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                registration &&
                  registration.waiting &&
                  registration.waiting.postMessage &&
                  registration.waiting.postMessage({ type: "SKIP_WAITING" });
                // eslint-disable-next-line no-undef
                window.location.reload(true);
              }}
            >
              Reload
            </Button>
          </>
        ),
        duration: 0,
      });
    }
  },
});
