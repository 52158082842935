import { useContext } from "react";

//! User Files

import * as ActionTypes from "common/actionTypes";
import { AppContext } from "AppContext";
import { toast } from "common/utils";
import { SERVER_ERROR } from "common/constants";

function useStatusCode() {
  const { dispatch } = useContext(AppContext);

  function checkAndHandleStatusCode(statusCode) {
    switch (statusCode) {
      case 401:
        dispatch({ type: ActionTypes.LOGOUT });
        toast({
          message: "Successfully Logged Out",
          type: "success",
        });
        return;
      case 500:
        toast({
          message: "Server Error",
          type: "error",
        });
        return SERVER_ERROR;
      default:
        break;
    }
  }
  return [checkAndHandleStatusCode];
}

export default useStatusCode;
