import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_SYNC_STATUS_SUBSCRIPTION = gql`
  subscription checkSyncStatus($userId: Int!) {
    checkSyncStatus(userId: $userId) {
      syncInProgress
      statusCode
      statusMessage
    }
  }
`;
