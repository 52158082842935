import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_CONTACTS_LIST_OF_GROUP = gql`
  query getGroupDetails($groupId: Int!, $offset: Int!, $limit: Int!, $query: String) {
    getGroupDetails(groupId: $groupId, offset: $offset, limit: $limit, query: $query) {
      data {
        contactList {
          displayName
          displayPhoto
          isEditable
          contactId
        }
        sharedUserList {
          id
          name
          email
          profile_picture
          shareStatus
          groupSharingId
          groupSharingAccessType
          planStatus
        }
        groupAdminInfo {
          id
          name
          email
          profile_picture
          planStatus
        }
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_CONTACT_INFO = gql`
  query contactInfo($contactId: Int!) {
    contactInfo(contactId: $contactId) {
      data {
        id
        metadata
      }
      statusCode
      statusMessage
    }
  }
`;
