//! Ant Imports
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";

function DoNotCancelModal({ visible, handleModalCancel, handleUserAction, popupType, loading }) {
   let content = '';
   let title = '';
  if(popupType === 'cancel') {
    title = <h2>Are you sure you want to cancel your subscription?</h2>;
    content = <div>
      <p className="cb-cancel-title">If you cancel your subscription, the following will happen:</p>
      <ul className="cb-cancel-detail">
        <li className="cb-cancel-detail-list">All users will not be able to share contacts.</li>
        <li className="cb-cancel-detail-list">All users will not be able to manage contacts(add/edit/delete) from ContactBook interface.</li>
        <li>Changes in Google Contacts will not reflect in shared users accounts as the sync will not work for all users.</li>
      </ul>
    </div>
  }
  return (
    <Modal
      className="cb-cancel-subscription-modal"
      centered
      title={title}
      visible={visible}
      onCancel={handleModalCancel}
      onOk={handleUserAction}
      confirmLoading={loading}
      okText="Yes"
      width={650}
    >
      <Text>
        {content}
      </Text>
    </Modal>
  );
}

export default DoNotCancelModal;
