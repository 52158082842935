import { useContext } from "react";
import { Route, Switch } from "react-router-dom";

//! User Files

import { AppContext } from "AppContext";
import { ROUTES, SERVER_ERROR } from "common/constants";
import { getUserStatus } from "common/utils";
import Dashboard from "modules/dashboard";
import Account from "modules/account";
import Pricing from "modules/pricing";
import Support from "modules/support";
import LogsHistory from "modules/logs";
import Error404 from "Error404";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_PLAN } from "modules/pricing/graphql/Queries";
import useStatusCode from "common/hooks/useStatusCode";
import * as ActionTypes from "common/actionTypes";

const ContentRoutes = () => {
  const {
    state: { currentUser }, dispatch
  } = useContext(AppContext);

  const isAdmin = currentUser?.isAdmin;
  const domainName = currentUser?.domainName;
  const userEmail = currentUser?.userEmail;
  const isUpdatedSubscription = currentUser?.isUpdatedSubscription;
  const isOldUser = currentUser?.isOldUser;
  const isNonAdminGSuiteUser = getUserStatus(domainName, userEmail);
  const [checkAndHandleStatusCode] = useStatusCode();

  // eslint-disable-next-line no-unused-vars
  const { data: currentPlan } = useQuery(GET_CURRENT_PLAN, {
    context: {
      headers: {
        consent: true,
      },
    },
    variables: {
      domainName,
      isUpdatedSubscription: isOldUser ? isUpdatedSubscription : true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ getCurrentPlan }) {
      const { data, statusCode } = getCurrentPlan;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        return;
      } else {
        dispatch({
          type: ActionTypes.SET_DOMAIN_PLAN,
          data,
        });
      }
    },
  });

  const renderRoutes =
    isAdmin || isNonAdminGSuiteUser ? (
      <Switch>
        <Route path={ROUTES.MAIN} exact render={(props) => <Dashboard {...props} /> } />
        <Route path={ROUTES.LABEL} exact component={Dashboard} />
        <Route path={ROUTES.ACCOUNT} exact component={Account} />
        <Route path={ROUTES.PRICING} exact component={Pricing} />
        <Route path={ROUTES.SUPPORT} exact component={Support} />
        <Route path={ROUTES.LOGS} exact component={LogsHistory} />
        <Route path="*" exact component={Error404} />
      </Switch>
    ) : (
      <Switch>
        <Route path={ROUTES.MAIN} exact component={Dashboard} />
        <Route path={ROUTES.LABEL} exact component={Dashboard} />
        <Route path={ROUTES.SUPPORT} exact component={Support} />
        <Route path={ROUTES.LOGS} exact component={LogsHistory} />
        <Route path="*" exact component={Error404} />
      </Switch>
    );

  return renderRoutes;
};

export default ContentRoutes;
