import { Link } from "react-router-dom";
//! Ant Imports

import Button from "antd/lib/button";
import Typography from "antd/lib/typography";

//! Ant Icons

import MailTwoTone from "@ant-design/icons/MailTwoTone";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

const { Title, Text } = Typography;

function Support() {
  const mailLink =
    "https://mail.google.com/mail/u/0/?view=cm&fs=1&to=support@contactbook.app&su=Support&body=Please write your message here&tf=1";
  const faqsLink = "https://contactbook.app/faq";

  const handleClick = () => {
    window.open(mailLink);
  };

  return (
    <div className="support">
      <div className="support-home-link">
        <Link to="/">
          <ArrowLeftOutlined /> Go to Dashboard
        </Link>
      </div>
      <div className="support-mail-icon">
        <MailTwoTone className="cb-svg-lg" />
      </div>
      <div className="support-heading">
        <Title className="cb-text-center">EMAIL SUPPORT</Title>
      </div>
      <div className="support-description">
        <Text type="secondary" className="cb-text-center">
          Email us at{" "}
          <a href={mailLink} target="_blank" rel="noreferrer">
            support@contactbook.app
          </a>{" "}
          we will get back to you in 24 hours.
        </Text>
      </div>
      <div className="support-button">
        <Button type="primary" onClick={handleClick}>
          Send Mail
        </Button>
      </div>
      <div className="support-faqs">
        <Text type="secondary" className="cb-text-center">
          Make sure you have read our{" "}
          <a href={faqsLink} target="_blank" rel="noreferrer">
            FAQs
          </a>
        </Text>
      </div>
    </div>
  );
}

export default Support;
