import { useContext, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import ReactGA from "react-ga";
import find from "lodash/find";
import filter from "lodash/filter";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

//! Ant Imports

import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Switch from "antd/lib/switch";
import Typography from "antd/lib/typography";

//! User Files

import { AppContext } from "AppContext";
import { oldPlans, newPlans } from "common/getPlans";
import { getPaddleProductId } from "common/getPaddleProductId";
import PricingCard from "./components/PricingCard";
import PaymentSuccessModal from "./components/PaymentSuccessModal";
import { SERVER_ERROR } from "common/constants";
import * as ActionTypes from "common/actionTypes";
import useStatusCode from "common/hooks/useStatusCode";
import DowngradeModal from "./components/DowngradeModal";
import { toast } from "common/utils";

//!Graphql imports
import { GET_CURRENT_PLAN } from "./graphql/Queries";
import { UPDATE_PLAN } from "./graphql/Mutation";
import { GET_ORGANIZATION_SHARED_USERS } from "modules/account/graphql/Queries";
import SwitchPlanConfirmPopup from "./components/SwitchPlanConfirmPopup";

const { Title, Text } = Typography;

const mailLink =
  "https://mail.google.com/mail/u/0/?view=cm&fs=1&to=support@contactbook.app&su=Support&body=Please write your message here&tf=1";

function Pricing() {
  const {
    state: { currentUser, userId, planData },
    dispatch
  } = useContext(AppContext);
  const [checked, setChecked] = useState(true);
  const [visible, setVisible] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [planDetails, setPlanDetails] = useState({
    plan: {},
    slug: "",
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [sharedUsers, setSharedUsers] = useState();
  const [downgradePopup, showDowngradePopup] = useState(false);
  const [confirmPopup, showConfirmPopup] = useState(false);
  const domainName = currentUser?.domainName;
  const isUpdatedSubscription = currentUser?.isUpdatedSubscription;
  const migrationStatus = currentUser?.migrationStatus;
  const isOldUser = currentUser?.isOldUser;
  const [checkAndHandleStatusCode] = useStatusCode();
  let extractNewPlans = cloneDeep(newPlans);
  let extractOldPlans = cloneDeep(oldPlans);

  const onChange = (checked) => {
    setChecked(checked);
  };
  const handleClick = () => {
    window.open(mailLink);
  };

  const [getCurrentPlanOfDomain] = useLazyQuery(GET_CURRENT_PLAN, {
    context: {
      headers: {
        consent: true,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ getCurrentPlan }) {
      setDataLoading(false);
      const { data, statusCode } = getCurrentPlan;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        return;
      } else {
        dispatch({
          type: ActionTypes.SET_DOMAIN_PLAN,
          data,
        });
      }
    },
  });

  const { data: sharedUserList } = useQuery(GET_ORGANIZATION_SHARED_USERS, {
    fetchPolicy: "network-only",
    variables: { domainName: domainName },
    onCompleted(data){
    },
    onError(error) {
      dispatch({
        type: ActionTypes.SET_DOMAIN_SHARED_USERS,
        data: [],
      });
    },
  });

  useEffect(() => {
    if(sharedUserList?.getOrganizationSharedUser?.data) {
      setSharedUsers(sharedUserList?.getOrganizationSharedUser?.data?.length)
    }
  }, [sharedUserList]);

  const handleSubscription = (selectedPlan, selectedPlanId, planSlug) => {
    if(planData?.status === "trialing") {
      showDowngradePopup(true);
      return;
    }
    setPlanDetails({
      plan: selectedPlan,
      slug: planSlug,
    });
    //! Paddle Subscription
    window.Paddle.Checkout.open({
      product: getPaddleProductId(planSlug),
      email: currentUser?.userEmail,
      passthrough: {
        domainName: currentUser?.domainName,
        planId: selectedPlanId,
        userId,
        isUpdatedSubscription: currentUser?.isUpdatedSubscription,
      },
      successCallback: (data) => {
        setTimeout(() => {
          setDataLoading(true);
          setVisible(true);
        }, 1000);

        setTimeout(() => {
          getCurrentPlanOfDomain({
            variables: {
              domainName,
              isUpdatedSubscription: isOldUser ? isUpdatedSubscription : true,
            },
          });
        }, 5000);

        ReactGA.event({
          category: "Paid Plan",
          action: selectedPlan,
        });
      },
    });
  };

  const cancelSubscription = (selectedPlan, selectedPlanId, planSlug) => {
    setPlanDetails({
      plan: selectedPlan,
      slug: planSlug,
    });
    window.Paddle.Checkout.open({
      product: getPaddleProductId(planSlug),
      email: currentUser.userEmail,
      passthrough: {
        domainName: currentUser?.domainName,
        planId: selectedPlanId,
        userId,
        isUpdatedSubscription: currentUser?.isUpdatedSubscription,
      },
      override: planData?.paddleData?.cancel_url,
      successCallback: (data) => {
        setTimeout(() => {
          setCancelPopup(true);
          getCurrentPlanOfDomain({
            variables: {
              domainName,
              isUpdatedSubscription,
            },
          });
        }, 1500);
      },

    });
  }

  const updatePlan = (selectedPlan, selectedPlanId, planSlug, planPrice, userSwitchPlan = false) => {
    if(sharedUsers > selectedPlan.limit) {
      showDowngradePopup(true);
      return;
    }
    if(planData?.status === "trialing") {
      showDowngradePopup(true);
      return;
    }
    setPlanDetails({
      plan: selectedPlan,
      slug: planSlug,
      selectedPlanId: selectedPlanId,
      planPrice: planPrice
    });
    showConfirmPopup(true);

    if(userSwitchPlan) {
      showConfirmPopup(false);
      updateSubscription({
        variables: {
          data: {
            subscriptionId: parseInt(planData?.paddleData?.subscription_id, 10),
            quantity: 1,
            recurringPrice: planSlug?.includes("Yearly") ? planPrice * 12 : planPrice,
            currency: "USD",
            billImmediately: true,
            planId: parseInt(getPaddleProductId(planSlug), 10),
            passthrough: {
              domainName: currentUser?.domainName,
              planId: selectedPlanId,
              userId,
            }
          }
        }
      })
    }
  }

  const [updateSubscription, { loading: updateLoading }] = useMutation(UPDATE_PLAN, {
    onCompleted({ updatePlan }){
      if(updatePlan.data.success) {
        setTimeout(() => {
          setDataLoading(true);
          setVisible(true);
        }, 1000);

        setTimeout(() => {
          getCurrentPlanOfDomain({
            variables: {
              domainName,
              isUpdatedSubscription: isOldUser ? isUpdatedSubscription : true,
            },
          });
        }, 5000);

        ReactGA.event({
          category: "Paid Plan",
          action: planDetails.plan,
        });
      } else {
        toast({
          message: updatePlan?.data?.error?.message,
          type: "error"
        });
      }
    },
    onError(){
      toast({
        message: "Something went wrong",
        type: "error"
      });
    }
  });

  let cbMonthlyPlans = [];
  let cbYearlyPlans = [];
  let monthlyPlans = [];
  let yearlyPlans = [];
  if( (planData.planId === null || planData.planId === "" || (planData.planId === 9 && planData.status !== "deleted")) ) {
    cbMonthlyPlans = filter(extractNewPlans, e => e.planKey.includes('Monthly'));
    cbYearlyPlans = filter(extractNewPlans, e => e.planKey.includes('Yearly'));

  //!This may be used in near future
   } else if (planData.planId === 9 && planData.status === "deleted") {
    cbMonthlyPlans = filter(extractOldPlans, e => e.planKey.includes('Monthly'));
    cbYearlyPlans = filter(extractOldPlans, e => e.planKey.includes('Yearly'));

  } else if (planData.planId >= 10 && planData.planId <= 15) {
    const findPlan = find(extractNewPlans, (e) => (e.planKey === planData.planKey));
    monthlyPlans = filter(extractOldPlans, e => e.planKey.includes('Monthly'));
    yearlyPlans = filter(extractOldPlans, e => e.planKey.includes('Yearly'));
    if(planData.planKey.includes('Monthly')) {
      cbMonthlyPlans.push(findPlan);
      cbMonthlyPlans.push(...filter(monthlyPlans, e => e.id !== findPlan.id));
      cbYearlyPlans.push(...yearlyPlans);
    } else {
      cbYearlyPlans.push(findPlan);
      cbMonthlyPlans.push(...monthlyPlans);
      cbYearlyPlans.push(...filter(yearlyPlans, e =>  e.id !== findPlan.id));
    }

  } else {
    monthlyPlans = filter(extractOldPlans, e => e.planKey.includes('Monthly'));
    yearlyPlans = filter(extractOldPlans, e => e.planKey.includes('Yearly'));
    if(!migrationStatus) {
      cbMonthlyPlans = monthlyPlans;
      cbYearlyPlans = yearlyPlans;
    }else {
      cbMonthlyPlans.push(extractOldPlans[0]);
      cbYearlyPlans.push(extractOldPlans[0]);
      cbMonthlyPlans.push(...monthlyPlans);
      cbYearlyPlans.push(...yearlyPlans);
    }
  }

  let cbPlans = checked ? cbYearlyPlans : cbMonthlyPlans;
  const renderPricingCards = cbPlans && cbPlans.map((plan) => {
    const { id } = plan;
    return (
      <Card className="pricing-plan" key={id} hoverable>
        <PricingCard
          plan={plan}
          checked={checked}
          handleSubscription={handleSubscription}
          cancelSubscription={cancelSubscription}
          updatePlan={updatePlan}
          updateLoading={updateLoading ?? false }
          planDetails={planDetails}
        />
      </Card>
    );
  });

  const closePopup = () => {
    showDowngradePopup(false);
  }

  const switchPlan = () => {
    updatePlan(planDetails?.plan, planDetails?.selectedPlanId, planDetails?.slug, planDetails?.planPrice, true);
  }

  const closeSwitchPlanPopup = () => {
    showConfirmPopup(false);
  }

  //!Kept this comment for future reference
  // if (!currentUser?.isConsentGiven) return <Redirect to="/" />;
  return (
    <>
      <SwitchPlanConfirmPopup
        confirmPopup={confirmPopup}
        switchPlan={switchPlan}
        closeSwitchPlanPopup={closeSwitchPlanPopup}
      />
      {downgradePopup &&
        <DowngradeModal
          visible={downgradePopup}
          closePopup={closePopup}
          planStatus={planData?.paddleData?.status}
        />
      }
      {visible &&
        <PaymentSuccessModal
          visible={visible}
          planDetails={planDetails}
          cancelPopup={cancelPopup}
          loading={dataLoading}
        />
      }
      <div className="pricing">
        <div className="pricing-heading">
          <Title level={1} className="cb-text-center">
            Plans
          </Title>
        </div>
        <div className="pricing-mode">
          <Text className="cb-text-strong">Monthly</Text>
          <Switch defaultChecked checked={checked} onChange={onChange} />
          <Text className="cb-text-strong">Yearly</Text>
        </div>
        <div className="pricing-plans">
          <div className="fixed-plans">{renderPricingCards}</div>
          <div className="enterprise-plans">
            <Card>
              <Title level={4}>ENTERPRISE</Title>
              <Text type="secondary" className="cb-text-center">
                Need more users? Give us a shout out for a pricing plan that’s
                tailored to your needs!
              </Text>
              <Button onClick={handleClick} type="primary">
                Contact us
              </Button>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
