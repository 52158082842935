import { useEffect, useContext, useState, Suspense, lazy } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import sortBy from "lodash/sortBy";
import clsx from "clsx";
import ReactGA from "react-ga";

//! Ant Imports

import Row from "antd/lib/row";
import Col from "antd/lib/col";

//! User Files

import * as ActionTypes from "common/actionTypes";
import { AppContext } from "AppContext";
import { SERVER_ERROR } from "common/constants";
import { getUserStatus, notificationToast, toast } from "common/utils";
import { MIGRATION_STATUS } from "common/messages/migrationStatus";
import { MIGRATION_MESSAGES } from "common/messages/migrationMessages";
import { GROUP_FILTERS } from "common/messages/groupFilters";
import useStatusCode from "common/hooks/useStatusCode";
import ConsentForm from "modules/auth/components/ConsentForm";
import MigrationModal from "modules/migration";
import MarketPlaceApproval from "modules/marketplace";
import OnboardingSlidesModal from "modules/onboarding";
import InformativeMessages from "./components/InformativeMessages";
import EmptyModal from "./components/EmptyModal";
//! In version 1.3.0, non admin G-Suite user can signup without admin delegation
// import NoDelegationByAdmin from "./components/NoDelegationByAdmin";

//! GraphQL

import { SET_USER_CONSENT } from "modules/users/graphql/Mutations";
import { START_MIGRATION } from "modules/migration/graphql/Mutations";
import { START_SYNC } from "modules/sync/graphql/Mutations";
import { SET_ONBOARDING_DONE, SET_USER_PERMISSION } from "modules/users/graphql/Mutations";
import { GET_DOMAIN_USERS_LIST } from "modules/users/graphql/Queries";
import { GET_GROUPS_LIST } from "modules/groups/graphql/Queries";
import { GET_CONTACTS_LIST_OF_GROUP } from "modules/contacts/graphql/Queries";
import { GET_CURRENT_PLAN } from "modules/pricing/graphql/Queries";
import { GET_MP_APP_STATUS } from "modules/auth/graphql/Queries";
import { GET_SYNC_STATUS } from "modules/sync/graphql/Queries";
import { GET_MIGRATION_STATUS } from "modules/migration/graphql/Queries";

//! Lazy Components

const GroupList = lazy(() => import("modules/groups"));
const ContactList = lazy(() => import("modules/contacts"));
const UserList = lazy(() => import("modules/users"));

const Dashboard = () => {
  const params = useParams();
  const {
    state: {
      currentUser,
      userId,
      syncInProgress,
      planData,
      contactsFetchLimit,
    },
    dispatch,
  } = useContext(AppContext);
  const [checkAndHandleStatusCode] = useStatusCode();

  const isGroupSelected = params?.groupId !== undefined;

  const {
    MIGRATION_STATUS_COMPLETED,
    MIGRATION_STATUS_IN_PROGRESS,
  } = MIGRATION_STATUS;

  const isOldUser = currentUser?.isOldUser;
  const userEmail = currentUser?.userEmail;
  const isAdmin = currentUser?.isAdmin;
  const domainName = currentUser?.domainName;
  const isUpdatedSubscription = currentUser?.isUpdatedSubscription;
  const isConsentGiven = currentUser?.isConsentGiven;
  const migrationStatus = currentUser?.migrationStatus;
  const hasLoggedInBefore = currentUser?.hasLoggedInBefore;
  const planKey = planData?.planKey;
  const isFreePlan = planKey === "free";
  const isGmailUser = userEmail.includes("gmail");
  const isNonAdminGSuiteUser = getUserStatus(domainName, userEmail);

  const isMigrationRequired =
    isOldUser && migrationStatus !== MIGRATION_STATUS_COMPLETED;

  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);
  const [delegationGivenFlag, setDelegationGivenFlag] = useState(true); //* Assumption: User has already given delegation to avoid infinite loop between webapp and google marketplace
  const [groups, setGroups] = useState([]);
  const [isEmptyGroupList, setIsEmptyGroupList] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [groupAdmin, setGroupAdmin] = useState([]);
  const [isGroupServerError, setIsGroupServerError] = useState(false);
  const [isContactServerError, setIsContactServerError] = useState(false);
  const [consentModalState, setConsentModalState] = useState({
    visible: false,
    confirmLoading: false,
  });
  const [migrationModalState, setMigrationModalState] = useState({
    visible: false,
    confirmLoading: false,
  });
  const [userModal, setUserModal] = useState(false);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState(undefined);
  const [isOnboardingDone, setIsOnboardingDone] = useState(false);

  const [getCurrentPlanOfDomain] = useLazyQuery(GET_CURRENT_PLAN, {
    context: {
      headers: {
        consent: true,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ getCurrentPlan }) {
      const { data, statusCode } = getCurrentPlan;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        return;
      } else {
        dispatch({
          type: ActionTypes.SET_DOMAIN_PLAN,
          data,
        });
      }
    },
  });

  const {
    loading: domainDelegationLoading,
    data: delegationData,
    refetch: refetchDelegationStatus,
  } = useQuery(GET_MP_APP_STATUS, {
    context: {
      headers: {
        consent: true,
      },
    },
    variables: { userEmail },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [getMigrationStatusOfUser] = useLazyQuery(GET_MIGRATION_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ getMigrationStatus }) {
      const { migrationStatus, statusCode } = getMigrationStatus;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        return;
      } else {
        if (migrationStatus === MIGRATION_STATUS_COMPLETED) {
          setMigrationModalState({
            visible: false,
            confirmLoading: false,
          });
        } else {
          setMigrationModalState({
            ...migrationModalState,
            visible: true,
          });
        }
        dispatch({
          type: ActionTypes.SET_MIGRATION_STATUS,
          data: migrationStatus,
        });
      }
    },
  });

  const [getDomainUserList] = useLazyQuery(GET_DOMAIN_USERS_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ getDomainUserList }) {
      const { data, statusCode } = getDomainUserList;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        dispatch({
          type: ActionTypes.SET_DOMAIN_USERS_FETCH_ERROR,
          data: true,
        });
        return;
      }
      dispatch({
        type: ActionTypes.SET_DOMAIN_USERS,
        data: sortBy(data),
      });
    },
    onError(error) {
      dispatch({
        type: ActionTypes.SET_DOMAIN_USERS_FETCH_ERROR,
        data: error,
      });
    },
  });

  const [
    getGroups,
    { loading: groupLoading, error: groupError, data: groupData },
  ] = useLazyQuery(GET_GROUPS_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onError(error) {
      setIsGroupServerError(true);
    },
  });

  const [
    getContacts,
    {
      loading: contactLoading,
      error: contactError,
      data: contactData,
      fetchMore: fetchMoreContacts,
    },
  ] = useLazyQuery(GET_CONTACTS_LIST_OF_GROUP, {
    fetchPolicy: "network-only",
    onError(error) {
      setIsContactServerError(true);
    },
  });

  const [setUserConsent] = useMutation(SET_USER_CONSENT, {
    context: {
      headers: {
        consent: true,
      },
    },
    onCompleted({ consentGiven }) {
      const { statusCode } = consentGiven;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        toast({
          message: "Something went wrong. Please try again!",
          type: "error",
        });
        setConsentModalState({
          visible: true,
          confirmLoading: false,
        });
      } else {
        ReactGA.event({
          category: "Sign Up",
          action: "User has sign up",
        });
        dispatch({ type: ActionTypes.SET_CONSENT_GIVEN });
        setConsentModalState({
          visible: false,
          confirmLoading: false,
        });

        if (isMigrationRequired) {
          setTimeout(() => {
            setMigrationModalState({
              ...migrationModalState,
              visible: true,
            });
          }, 1000);
        } else {
          toast({
            message: "Thank you for registering!",
            type: "success",
            duration: 10,
          });
          if (!hasLoggedInBefore) {
            startSync({
              variables: { userId, isAdmin },
            });
            setOnboardingDone();
            setOnboardingModalVisible(true);
          }
          getGroups({
            variables: { userId, listType: GROUP_FILTERS.LIST_TYPE_VALUE_ALL },
          });
          getCurrentPlanOfDomain({
            variables: {
              domainName,
              isUpdatedSubscription: isOldUser ? isUpdatedSubscription : true,
            },
          });
        }
      }
    },
    onError() {
      toast({
        message: "Something went wrong. Please try again!",
        type: "error",
      });
      setConsentModalState({
        visible: true,
        confirmLoading: false,
      });
    },
  });

  const [startSync] = useMutation(START_SYNC, {
    context: {
      headers: {
        initialsync: !hasLoggedInBefore,
      },
    },
    refetchQueries: [
      {
        query: GET_SYNC_STATUS,
        variables: { userId },
      },
    ],
    onCompleted({ startSync }) {
      const { statusCode } = startSync;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        toast({
          message: "Something went wrong",
          type: "error",
        });
        return;
      } else {
        //! Removed toast message, as we won't show any sync status for the first time.
        dispatch({ type: ActionTypes.SET_SYNC_STATUS, data: true });
      }
    },
  });

  const [startUserMigration] = useMutation(START_MIGRATION, {
    onCompleted({ startMigration }) {
      const { statusCode } = startMigration;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        toast({
          message: "Something went wrong. Please try again!",
          type: "error",
        });
        setMigrationModalState({
          visible: true,
          confirmLoading: false,
        });
      } else {
        dispatch({
          type: ActionTypes.SET_MIGRATION_STATUS,
          data: MIGRATION_STATUS_IN_PROGRESS,
        });
        toast({
          message: MIGRATION_MESSAGES.IN_PROGRESS,
          type: "info",
          duration: 5,
        });
        setMigrationModalState({
          visible: true,
          confirmLoading: false,
        });
      }
    },
    onError() {
      toast({
        message: "Something went wrong. Please try again!",
        type: "error",
      });
      setMigrationModalState({
        visible: true,
        confirmLoading: false,
      });
    },
  });

  const [setOnboardingDone] = useMutation(SET_ONBOARDING_DONE);

  const [setUserPermission] = useMutation(SET_USER_PERMISSION);

  if((planData?.planId === null || planData?.status === "deleted") && (isAdmin || isNonAdminGSuiteUser)) {
    history.push('/pricing');
  }

  useEffect(() => {
    if (delegationData?.verifyDomainWideDelegationGiven) {
      const { delegationGiven, statusCode } = delegationData[
        "verifyDomainWideDelegationGiven"
      ];
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        return;
      } else {
        if (isGmailUser || !isAdmin) {
          dispatch({
            type: ActionTypes.SET_DELEGATION_FLAG,
            data: true,
          });
        } else {
          dispatch({
            type: ActionTypes.SET_DELEGATION_FLAG,
            data: delegationGiven,
          });
        }
        if (delegationGiven && isConsentGiven) {
          getDomainUserList();
        }
        setDelegationGivenFlag(delegationGiven);
      }
    }
    // eslint-disable-next-line
  }, [delegationData]);

  useEffect(() => {
    if (groupData?.getGroupList) {
      const { data, statusCode } = groupData.getGroupList;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        setIsGroupServerError(true);
        return;
      }
      const groupNotExistInFilter = !data.filter(
        (group) => group.groupId === parseInt(params?.groupId)
      ).length;
      if (!data.length || groupNotExistInFilter) {
        setIsEmptyGroupList(true);
      } else {
        setIsEmptyGroupList(false);
      }
      const sortedGroupList = sortBy(data, "groupName");
      setGroups(sortedGroupList);
    }

    // eslint-disable-next-line
  }, [groupData, params.groupId]);

  useEffect(() => {
    if (contactData?.getGroupDetails) {
      const { data, statusCode } = contactData.getGroupDetails;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        setIsContactServerError(true);
        return;
      }
      const { contactList, sharedUserList, groupAdminInfo } = data;
      const sortedContactList = sortBy(contactList, "displayName");
      const sortedSharedUsersList = sortBy(sharedUserList, "name");
      setContacts(sortedContactList || []);
      setSharedUsers(sortedSharedUsersList || []);
      setGroupAdmin(groupAdminInfo || []);
    }
    // eslint-disable-next-line
  }, [contactData]);

  useEffect(() => {
    if (isGroupSelected) {
      getContacts({
        variables: {
          groupId: parseInt(params.groupId),
          offset: 0,
          limit: contactsFetchLimit,
        },
      });
    }
    // eslint-disable-next-line
  }, [isGroupSelected, params.groupId]);

  useEffect(() => {
    if (!isConsentGiven && isConsentGiven !== null) {
      setTimeout(() => {
        setConsentModalState({
          ...consentModalState,
          visible: true,
        });
      }, 500);
    } else if (isMigrationRequired) {
      getMigrationStatusOfUser({
        variables: { userEmail },
      });
    } else {
      if (!hasLoggedInBefore) {
        startSync({
          variables: { userId, isAdmin },
        });
        setOnboardingDone();
        setOnboardingModalVisible(true);
      }
      getGroups({
        variables: { userId, listType: GROUP_FILTERS.LIST_TYPE_VALUE_ALL },
      });
      getCurrentPlanOfDomain({
        variables: {
          domainName,
          isUpdatedSubscription: isOldUser ? isUpdatedSubscription : true,
        },
      });
    }
    // eslint-disable-next-line
  }, [isMigrationRequired]); //* We will only put this dependency as we don't have control when it will be finished, so it will re-render component when it's done

  useEffect(() => {
    if (
      migrationModalState.visible &&
      migrationStatus === MIGRATION_STATUS_COMPLETED
    ) {
      setMigrationModalState({
        visible: false,
        confirmLoading: false,
      });
    }
    // eslint-disable-next-line
  }, [migrationStatus]);

  useEffect(() => {
    if(isOnboardingDone) {
      const openPopup = () => {
        setUserModal(true);
      }
      openPopup();
    }
  }, [isOnboardingDone]);

  const handleOnboardingDone = () => {
    setOnboardingModalVisible(false);
    dispatch({ type: ActionTypes.SET_ONBOARDING_DONE });
    if (syncInProgress) {
      notificationToast({
        message: "Sync is in progress",
        description: "Please wait while we sync your data",
      });
    } else {
      setIsOnboardingDone(true);
      //! Keeping the below comment for reference
      // window.location.reload();
    }
  };

  const handleConsent = () => {
    setConsentModalState({
      ...consentModalState,
      confirmLoading: true,
    });
    setUserConsent({
      variables: { userId },
    });
  };

  const handleMigration = () => {
    setMigrationModalState({
      ...migrationModalState,
      confirmLoading: true,
    });
    startUserMigration({
      variables: { userEmail },
    });
  };

  const handleFilterSelection = (listType) => {
    getGroups({ variables: { userId, listType } });
  };

  useEffect(() => {
    if(searchValue !== undefined) {
      const handler = setTimeout(() => {
        getContacts({
          variables: {
            groupId: parseInt(params.groupId),
            offset: 0,
            limit: contactsFetchLimit,
            query: searchValue ?? undefined
          },
        });
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, contactsFetchLimit, params]);

  const handleContactChange = (e) => {
    const { value } = e.target;
    const searchParameter = value.trim(" ");
    setSearchValue(searchParameter);
  };

  if (isAdmin && !isGmailUser && !delegationGivenFlag)
    return (
      <MarketPlaceApproval
        domain={domainName}
        loading={domainDelegationLoading}
        refetch={refetchDelegationStatus}
      />
    );

  const handleModalPopup = () => {
    setUserModal(false);
    setIsOnboardingDone(false);
  }
  //! In version 1.3.0, non admin G-Suite user can signup without admin delegation
  // if (!isAdmin && !delegationGivenFlag) return <NoDelegationByAdmin />;
  return (
    <>
    { userModal && <EmptyModal handleModalPopup={handleModalPopup} /> }
    { (planData?.planId !== null)  &&
    <>
      <ConsentForm
        consentModalState={consentModalState}
        handleConsent={handleConsent}
        />
      <MigrationModal
        migrationModalState={migrationModalState}
        handleMigration={handleMigration}
        />
      <OnboardingSlidesModal
        onboardingModalVisible={onboardingModalVisible}
        handleOnboardingDone={handleOnboardingDone}
        />
      </>
      }
      <div className="dashboard cb-height-inherit">
        <Row className="dashboard-row cb-height-inherit cb-scrollbar">
          <InformativeMessages planKey={planKey} isFreePlan={isFreePlan} />
          <Col
            className={clsx(
              "dashboard-col",
              "group-list-wrapper",
              isFreePlan ? "cb-height-inherit-free" : "cb-height-inherit"
            )}
            xs={24}
            md={12}
            lg={8}
          >
            <Suspense fallback={<div />}>
              <GroupList
                groupLoading={groupLoading}
                groupError={groupError}
                isGroupServerError={isGroupServerError}
                groups={groups}
                handleFilterSelection={handleFilterSelection}
                groupAdmin={groupAdmin}
              />
            </Suspense>
          </Col>
          <Col
            className={clsx(
              "dashboard-col",
              "contact-list-wrapper",
              isFreePlan ? "cb-height-inherit-free" : "cb-height-inherit"
            )}
            xs={24}
            md={12}
            lg={8}
          >
            <Suspense fallback={<div />}>
              <ContactList
                isEmptyGroupList={isEmptyGroupList}
                contactLoading={contactLoading}
                contactError={contactError}
                isContactServerError={isContactServerError}
                isGroupSelected={isGroupSelected}
                contacts={contacts}
                groupId={parseInt(params.groupId)}
                fetchMoreContacts={fetchMoreContacts}
                sharedUsers={sharedUsers}
                groupAdmin={groupAdmin}
                handleContactChange={handleContactChange}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                groups={groups}
              />
            </Suspense>
          </Col>
          <Col
            className={clsx(
              "dashboard-col",
              "user-list-wrapper",
              isFreePlan ? "cb-height-inherit-free" : "cb-height-inherit"
            )}
            xs={24}
            md={24}
            lg={8}
          >
            <Suspense fallback={<div />}>
              <UserList
                contactLoading={contactLoading}
                contactError={contactError}
                isContactServerError={isContactServerError}
                isGroupSelected={isGroupSelected}
                sharedUsers={sharedUsers}
                contacts={contacts}
                groupAdmin={groupAdmin}
                setUserPermission={setUserPermission}
              />
            </Suspense>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
