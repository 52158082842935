//! Ant Imports

import Spin from "antd/lib/spin";

//! Ant Icons

import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

function InfiniteLoader() {
  const spinner = <LoadingOutlined spin />;
  return <Spin className="cb-infinite-loading" indicator={spinner} />;
}

export default InfiniteLoader;
