import { Link } from "react-router-dom";

//!Ant import
import Modal from "antd/lib/modal";
import { Button } from "antd";

const UpgradePopup = ({ openUpgradePopup, visible, isAdmin, popupText }) => {

  const title = <h2>No active plan found</h2>
  return (
    <Modal
      className="cb-upgrade-modal"
      visible={visible}
      title={title}
      onCancel={() => openUpgradePopup(false)}
      centered={true}
      footer={null}
      width={600}
    >
      {isAdmin ?
        <div className="cb-upgrade-modal-body">
          <span>It seems you don't have any active plan. Please subscribe to a suitable plan to perform this action!</span>
          <Link to="/pricing" >
            <Button type="primary" htmlType="submit" onClick={() => openUpgradePopup(false)}>Subscribe</Button>
          </Link>
        </div> :
          <span>It seems like your domain doesn't have an active plan. Please connect with your admin to continue using the platform.</span>
      }
    </Modal>
  )
};


export default UpgradePopup;