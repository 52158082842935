import clsx from "clsx";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

//! Ant Imports

import notification from "antd/lib/notification";
import message from "antd/lib/message";

//! User Files

import {
  defaultDateFormat,
  REGEX,
  ERROR_CREATING_CONTACT,
  ERROR_CREATING_GROUP,
  ERROR_ASSIGNING_MEMBERS_TO_GROUP,
  ERROR_UPDATING_CONTACT,
  GROUP_CANNOT_BE_SHARED,
} from "./constants";
import { GROUP_FILTERS } from "./messages/groupFilters";

export const consentScreen = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=${process.env.REACT_APP_SCOPE}&response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

export const getMarketPlaceURL = (domain) => {
  return `https://workspace.google.com/marketplace/app/contactbook_contact_share_app/${process.env.REACT_APP_MP_APPLICATION_ID}`
};

export function useRouteQuery() {
  return new URLSearchParams(useLocation().search);
}

export const getDate = (timeValue) => {
  return timeValue ? moment(timeValue).format("D/MM/YYYY") : "";
};

export const getTime = (timeValue) => {
  if (timeValue) {
    const time = moment(timeValue).format("h:mm:ss A");
    return time === "Invalid date" ? "Pending" : time;
  } else {
    return "";
  }
};

export const formatGoogleDate = ({ day = "", month = "", year = "" }) => {
  const dd = day.toString();
  const mm = month.toString();
  const yy = year.toString();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (isEmpty(dd) || isEmpty(mm)) {
    const dateArray = clsx(month, day, year).split(" ");
    if (dateArray.length) {
      const date = dateArray.join("/");
      return date;
    }
  }
  if (!isEmpty(yy)) {
    return `${months[month - 1]} ${day}, ${year}`;
  }
  if (!isEmpty(dd) && !isEmpty(mm)) {
    return `${months[month - 1]} ${day}`;
  }
};

const {
  LIST_TYPE_VALUE_ALL,
  LIST_TYPE_VALUE_SHARED,
  LIST_TYPE_VALUE_NO_SHARED_WITH_ME,
  LIST_TYPE_VALUE_SHARED_WITH_ME,
} = GROUP_FILTERS;

export const filterMenuItems = [
  {
    key: LIST_TYPE_VALUE_ALL,
    value: "All",
  },
  {
    key: LIST_TYPE_VALUE_SHARED,
    value: "Shared",
  },
  {
    key: LIST_TYPE_VALUE_NO_SHARED_WITH_ME,
    value: "Not Shared",
  },
  {
    key: LIST_TYPE_VALUE_SHARED_WITH_ME,
    value: "Shared With Me",
  },
];

export const SHARED_LABEL_COUNT = {
  free: 10,
  startupMonthly: Infinity,
  startupYearly: Infinity,
  businessMonthly: Infinity,
  businessYearly: Infinity,
  corporateMonthly: Infinity,
  corporateYearly: Infinity,
  enterprise: Infinity,
};

export const CONTACTS_LIMIT_PER_LABEL = {
  free: 50,
  startupMonthly: Infinity,
  startupYearly: Infinity,
  businessMonthly: Infinity,
  businessYearly: Infinity,
  corporateMonthly: Infinity,
  corporateYearly: Infinity,
  enterprise: Infinity,
};

export const toast = (
  { message: content, type, duration = 3 },
  destroy = true
) => {
  if (destroy) {
    message.destroy();
  }
  switch (type) {
    case "info":
      message.info(content, duration);
      break;
    case "success":
      message.success(content, duration);
      break;
    case "warning":
      message.warning(content, duration);
      break;
    case "error":
      message.error(content, duration);
      break;
    default:
      break;
  }
};

export const notificationToast = ({ message, description = "" }) => {
  notification.open({
    message: <p className="cb-text-strong">{message}</p>,
    description,
    duration: 10,
  });
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const crossDomainUser = (email, domain) => {
  const emailDomain = email.split("@")[1].toLowerCase();
  return emailDomain === "gmail.com" || emailDomain === domain;
};

export const getUserStatus = (domain, email) => {
  const userDomainFromEmail = email.replace(/.*@/, "");
  if (userDomainFromEmail !== "gmail.com" && domain !== userDomainFromEmail) {
    return true;
  }
  return false;
};

export const getCompanyName = (domain) => {
  let companyName;
  if (domain.includes("gmail")) {
    companyName = "Gmail";
  } else {
    companyName = domain.split(".")[0];
  }
  return companyName;
};

export const getLogColorByStatus = (status) => {
  let color;
  switch (status) {
    case "COMPLETED":
      color = "green";
      break;
    case "PROGRESS":
      color = "processing";
      break;
    case "PENDING":
    default:
      color = "orange";
      break;
  }
  return color;
};

export const getMessageByType = (logs) => {
  const errorMessages = [],
    emailArray = [],
    loginRequiredEmails = [];
  let contactCreationError = 0,
    groupAlreadyExist = 0,
    assigningMemberIssue = 0,
    loginRequired = 0,
    contactUpdateFailed = 0;
  if (Array.isArray(logs)) {
    logs.forEach((log) => {
      if (log.includes(ERROR_CREATING_CONTACT)) {
        contactCreationError += 1;
      }
      if (log.includes(ERROR_CREATING_GROUP)) {
        groupAlreadyExist += 1;
        if (log.includes("FOR_")) {
          const email = log.split("FOR_")[1].split(":")[0];
          emailArray.push(email);
        }
      }
      if (log.includes(ERROR_ASSIGNING_MEMBERS_TO_GROUP)) {
        assigningMemberIssue += 1;
      }
      if (log.includes(ERROR_UPDATING_CONTACT)) {
        contactUpdateFailed += 1;
      }
      if (log.includes(GROUP_CANNOT_BE_SHARED)) {
        loginRequired += 1;
        const emailPart = log.split("SHARED_")[1];
        const email = emailPart.split("_LOGIN")[0];
        loginRequiredEmails.push(email);
      }
    });
    if (contactCreationError > 0) {
      errorMessages.push(
        `Contact creation failed for ${contactCreationError} ${
          contactCreationError === 1 ? "user" : "users"
        }`
      );
    }
    if (groupAlreadyExist > 0) {
      errorMessages.push(
        `Label already exists in ${
          emailArray.length
            ? emailArray.join(", ")
            : `${groupAlreadyExist} ${
                groupAlreadyExist === 1 ? "user" : "users"
              }`
        }`
      );
    }
    if (loginRequired > 0) {
      errorMessages.push(
        `Login required for ${
          loginRequiredEmails.length
            ? loginRequiredEmails.join(", ")
            : `${loginRequired} ${loginRequired === 1 ? "user" : "users"}`
        }`
      );
    }
    if (assigningMemberIssue > 0) {
      errorMessages.push(
        `Error occurred while assigning group for ${assigningMemberIssue} ${
          assigningMemberIssue === 1 ? "user" : "users"
        }`
      );
    }
    if (contactUpdateFailed > 0) {
      errorMessages.push(
        `Error occurred while updating contact for ${contactUpdateFailed} ${
          contactUpdateFailed === 1 ? "user" : "users"
        }`
      );
    }
    return errorMessages;
  } else {
    return ["Something went wrong while syncing data"];
  }
};

export const generateRandomColor = (theme) => {
  let color = "#";
  switch (theme) {
    case "dark":
      for (let i = 0; i < 3; i++)
        color += (
          "0" + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)
        ).slice(-2);
      return color;
    case "light":
    default:
      for (let i = 0; i < 3; i++)
        color += (
          "0" +
          Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)
        ).slice(-2);
      return color;
  }
};

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window.dispatchEvent(new Event("refresh-grid"));
};

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: "Required",
  },
  email: {
    type: "email",
    message: "The input is not valid E-mail!",
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("Should be a valid Number");
      }
      return Promise.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, "");

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export const userPermission = [
  {type: 'View', value: 'View'},
  {type: 'Edit', value: 'Edit'}
]

export const getTrimValues = (values) => {
  const newArr = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(values).map((key) => {
    // eslint-disable-next-line no-unused-expressions
    typeof values[key] === 'string' && key !== 'password'
      ? newArr[key] = values[key].trim()
      : newArr[key] = values[key];
  });

  return newArr;
};

export const splitEventByDate = (event) => {
  const newArr = cloneDeep(event);
  // eslint-disable-next-line array-callback-return
  newArr.map((eve, index) => {
    if(eve.date !== undefined) {
      const date = new Date(eve.date);
      newArr[index]['date'] = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }
  })
  return newArr;
}

export const EMPTY_FIELD = [{value: '', type: ''}];