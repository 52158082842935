import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_MP_APP_STATUS = gql`
  query verifyDomainWideDelegationGiven($userEmail: String!) {
    verifyDomainWideDelegationGiven(userEmail: $userEmail) {
      delegationGiven
      statusCode
      statusMessage
    }
  }
`;
