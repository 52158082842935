import { useContext } from "react";

//! Ant Imports

import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Checkbox from "antd/lib/checkbox";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";

//! Ant Icons

import UserOutlined from "@ant-design/icons/UserOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";

//! User Files

import { AppContext } from "AppContext";

function ConsentForm({ consentModalState, handleConsent }) {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { visible, confirmLoading } = consentModalState;
  const buttonText = confirmLoading ? "Registering" : "Register";
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        handleConsent(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const renderFooter = (
    <Button type="primary" loading={confirmLoading} onClick={handleOk}>
      {buttonText}
    </Button>
  );
  const isUsernameAvailable = currentUser?.userName?.length;
  const isUserEmailAvailable = currentUser?.userEmail?.length;

  const title = <span className="cb-text-strong">Register to Contactbook</span>;

  return (
    <Modal
      className="cb-consent-modal"
      visible={visible}
      centered
      closable={false}
      destroyOnClose
      keyboard={false}
      maskClosable={false}
      confirmLoading={confirmLoading}
      title={title}
      footer={renderFooter}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          displayName: currentUser?.userName,
          email: currentUser?.userEmail,
        }}
      >
        <Form.Item
          name="displayName"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            disabled={isUsernameAvailable}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
            disabled={isUserEmailAvailable}
          />
        </Form.Item>
        <Form.Item
          className="cb-checkbox"
          name="consent"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Required!",
              type: "boolean",
              transform: (value) => value || undefined,
            },
          ]}
        >
          <Checkbox>
            I agree to the{" "}
            <a
              href="https://contactbook.app/terms"
              target="_blank"
              rel="noreferrer"
            >
              terms and condition
            </a>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ConsentForm;
