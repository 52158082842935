import { useContext } from "react";
import { Link } from "react-router-dom";

//! Ant Imports

import Col from "antd/lib/col";
import Text from "antd/lib/typography/Text";

//! User Files

import { AppContext } from "AppContext";
import { ADMIN, USER } from "common/constants";
import { PLAN_BASED_MESSAGES } from "common/messages/getPlanRelatedMessages";

function InformativeMessages({ planKey, isFreePlan }) {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const USER_ROLE = currentUser?.isAdmin ? ADMIN : USER;

  return (
    isFreePlan && (
      <Col className="info-wrapper" span={24}>
        <Link to="/pricing">
          <Text type="secondary">
            {PLAN_BASED_MESSAGES[planKey][USER_ROLE]}
          </Text>
        </Link>
      </Col>
    )
  );
}

export default InformativeMessages;
