import { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

//! Ant Imports

import Layout from "antd/lib/layout";
import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";

//! User Files

import UserProfile from "modules/users/components/UserProfile";
import Scheduler from "modules/scheduler";
import Sync from "modules/sync";
import CBLogo from "assets/images/logo.png";
import * as ActionTypes from "common/actionTypes";
import { AppContext } from "AppContext";
import { ROUTES, SERVER_ERROR } from "common/constants";
import useStatusCode from "common/hooks/useStatusCode";
import { ReactComponent as AutoScheduler } from "assets/svg/logs/schedule.svg";

//! GraphQL

import { GET_MP_APP_STATUS } from "modules/auth/graphql/Queries";
import UpgradePopup from "components/UpgradePopup";

const { Header } = Layout;

const AppHeader = () => {
  const {
    state: { currentUser, delegationGiven, planData },
    dispatch,
  } = useContext(AppContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [checkAndHandleStatusCode] = useStatusCode();

  const userEmail = currentUser?.userEmail;
  const isAdmin = currentUser?.isAdmin;
  const isGmailUser = userEmail.includes("gmail");

  const { data: delegationData } = useQuery(GET_MP_APP_STATUS, {
    context: {
      headers: {
        consent: true,
      },
    },
    variables: { userEmail },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (delegationData?.verifyDomainWideDelegationGiven) {
      const { delegationGiven, statusCode } = delegationData[
        "verifyDomainWideDelegationGiven"
      ];
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        return;
      } else {
        if (isGmailUser || !isAdmin) {
          dispatch({
            type: ActionTypes.SET_DELEGATION_FLAG,
            data: true,
          });
        } else {
          dispatch({
            type: ActionTypes.SET_DELEGATION_FLAG,
            data: delegationGiven,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [delegationData]);

  const handleSchedulerClick = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  const text = <span className="cb-tooltip-description">Schedule Sync</span>;
  const popupText = isAdmin ? " the plan to continue schedule the auto sync time" :
  "Please contact your Admin to subscribe the plan to continue auto sync.";

  const renderRightHeaderDiv = (
    <>
      {delegationGiven && (
        <>
          { planData?.planId === null || planData?.status === "deleted" ?
            <UpgradePopup
              openUpgradePopup={handleCancel}
              visible={modalVisible}
              isAdmin={isAdmin}
              popupText={popupText}
            /> :
            <Scheduler visible={modalVisible} handleCancel={handleCancel} />
          }
          <Tooltip placement="bottom" title={text}>
            <Button
              className="scheduler-btn"
              type="link"
              icon={<AutoScheduler className="cb-svg" />}
              onClick={handleSchedulerClick}
            />
          </Tooltip>
          <Link to={ROUTES.LOGS}>
            <Button type="link">Logs</Button>
          </Link>
          <Sync />
        </>
      )}
      <UserProfile />
    </>
  );
  return (
    <Header className="app-header-wrapper">
      <div className="app-header-left">
        <Link to="/">
          <img className="app-header-logo" src={CBLogo} alt="Contactbook" />
        </Link>
      </div>
      <div className="app-header-right">{renderRightHeaderDiv}</div>
    </Header>
  );
};
export default AppHeader;
