import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_CURRENT_PLAN = gql`
  query getCurrentPlan($domainName: String!, $isUpdatedSubscription: Boolean!) {
    getCurrentPlan(
      domainName: $domainName
      isUpdatedSubscription: $isUpdatedSubscription
    ) {
      data {
        planId
        planName
        planKey
        userLimit
        validTill
        paddleData
        status
      }
      statusCode
      statusMessage
    }
  }
`;
