import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_MIGRATION_STATUS = gql`
  query getMigrationStatus($userEmail: String!) {
    getMigrationStatus(userEmail: $userEmail) {
      migrationStatus
      statusCode
      statusMessage
    }
  }
`;
