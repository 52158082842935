import { useCallback, useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment";

//! Ant Imports

import List from "antd/lib/list";
import Title from "antd/lib/typography/Title";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";

//! Ant Icons

import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

//! User Files

import { AppContext } from "AppContext";
import { toast } from "common/utils";
import { SERVER_ERROR } from "common/constants";
import useStatusCode from "common/hooks/useStatusCode";
import LogCard from "./components/LogCard";
import Loading from "components/Loading";
import InfiniteLoader from "components/InfiniteLoader";
import DataFetchError from "components/DataFetchError";

//! GraphQL

import { GET_SYNC_LOGS } from "./graphql/Queries";

function LogsHistory() {
  const {
    state: { userId },
  } = useContext(AppContext);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [checkAndHandleStatusCode] = useStatusCode();
  const [date, setDate] = useState(moment());
  const { loading, error, data, fetchMore } = useQuery(GET_SYNC_LOGS, {
    fetchPolicy: "network-only",
    variables: { offset: 0, limit: 10, date: date?.format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD') },
    onCompleted({ getSyncLogs }) {
      const { statusCode, statusMessage } = getSyncLogs;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        toast({
          message: statusMessage,
          type: "error",
        });
        return;
      }
    },
  });

  useEffect(() => {
    if (data?.getSyncLogs?.data.length === 0) {
      setHasMore(false);
    }
    return () => setHasMore(true);
  }, [data]);

  const normalRef = useRef(null);
  const observer = useRef(null);
  const lastLogRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setInfiniteLoading(true);
          fetchMore({
            variables: {
              offset: offset + 10,
              limit: 10,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                getSyncLogs: {
                  ...fetchMoreResult.getSyncLogs,
                  data: [
                    ...prev.getSyncLogs.data,
                    ...fetchMoreResult.getSyncLogs.data,
                  ],
                },
              });
            },
          })
            .then((fetchMoreResult) => {
              setInfiniteLoading(false);
              setOffset(offset + 10);
              if (!fetchMoreResult.data.getSyncLogs.data.length) {
                setHasMore(false);
                setOffset(0);
              }
            })
            .catch(() => {
              setInfiniteLoading(false);
            });
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line
    [loading, hasMore, offset]
  );
  if (loading) return <Loading />;
  if (error) return <DataFetchError />;

  const handleDateChange = (date) => {
    setDate(date);
  }

  return (
    <div className="logs cb-height-inherit">
      <div className="cb-logs-heading">
        <Row className="heading-row">
          <Col className="dashboard-link" xs={24} sm={12} md={12} lg={6}>
            <Link to="/">
              <ArrowLeftOutlined /> Go to Dashboard
            </Link>
          </Col>
          <Col className="title" xs={24} sm={12} md={12} lg={16}>
            <Title level={4} className="cb-text-center">
              Sync Logs
            </Title>
          </Col>
          <Col xs={24} sm={12} md={12} lg={5}>
            <DatePicker onChange={handleDateChange} value={date} clearIcon={false} />
          </Col>
        </Row>
      </div>
      <List
        className="logs-list"
        itemLayout="horizontal"
        dataSource={data.getSyncLogs.data}
        renderItem={(logInfo, index) => {
          const isLast = data.getSyncLogs.data.length === index + 1;
          return (
            <>
              <LogCard
                ref={isLast ? lastLogRef : normalRef}
                logInfo={logInfo}
                userId={userId}
                loading={loading}
              />
              {isLast && infiniteLoading && <InfiniteLoader />}
            </>
          );
        }}
      />
    </div>
  );
}

export default LogsHistory;
