import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_ORGANIZATION_SHARED_USERS = gql`
  query getOrganizationSharedUser($domainName: String!) {
    getOrganizationSharedUser(domainName: $domainName) {
      data {
        name
        email
        groupNames
      }
      statusCode
      statusMessage
    }
  }
`;
