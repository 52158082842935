import { forwardRef, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";

//! Ant Imports

import Tag from "antd/lib/tag";
import Typography from "antd/lib/typography";

//! Ant Icons

import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import ArrowRightOutlined from "@ant-design/icons/ArrowRightOutlined";

//! User Files

import SyncError from "./SyncError";
import { getDate, getLogColorByStatus, getTime } from "common/utils";
import { useLazyQuery } from "@apollo/client";
import { GET_CONTACT_INFO } from "modules/contacts/graphql/Queries";
import { SERVER_ERROR } from "common/constants";
import useStatusCode from "common/hooks/useStatusCode";

const { Text } = Typography;

const LogCard = forwardRef(({ logInfo, userId, loading }, ref) => {
  const {
    activityType,
    activityMessage,
    status,
    startTime,
    endTime,
    errorLogs,
    userIds,
    initiatorId,
    createdAt,
  } = logInfo;
  const [show, setShow] = useState(true);
  const [contactData, setContactData] = useState();
  const [logMessage, setLogMessage] = useState(activityMessage);
  const isLoggedInUser = userId === initiatorId;
  const [checkAndHandleStatusCode] = useStatusCode();

  useEffect(() => {
    if (
      activityType === "userSync" &&
      isLoggedInUser &&
      !userIds.includes(initiatorId)
    ) {
      setShow(false);
    }
    // eslint-disable-next-line
  }, []);

  const color = getLogColorByStatus(status);

  const [getContactInfo] = useLazyQuery(
    GET_CONTACT_INFO,
    {
      fetchPolicy: "network-only",
      onCompleted({ contactInfo }) {
        const { data, statusCode } = contactInfo;
        const status = checkAndHandleStatusCode(statusCode);
        if (status === SERVER_ERROR) {
          return;
        }
        setContactData(data);
      },
    }
  );

  useEffect(() => {
    const getContactName = (id, msgArr, index, type) => {
      if(typeof id === "number" && !isNaN(id)) {
        getContactById(id, msgArr);

        if (contactData?.metadata) {
          const { metadata } = contactData;
          const name = metadata?.names?.[0];
          const contactName = name?.unstructuredName;

          if(contactName) {
            if(activityType === type) {
              msgArr[index] = contactName;
              setLogMessage(msgArr.toString(' ').replace(/,/g, ' '));
            }
          }
        }
      }
    }

    const getContactById = (contactId) => {
      getContactInfo({
        variables: {
          contactId,
        },
      });
    }
    if (activityType === "groupShare" || activityType === "groupRevoke") {
      const messageArray = activityMessage.split("&&");
      const logMsg = isLoggedInUser ? messageArray[1] : messageArray[0];
      setLogMessage(logMsg);
    } else if(activityType === "edited_contact") {
      const msgArray = activityMessage.split(" ");
      const syncContactId = parseInt(msgArray[4], 10);
      getContactName(syncContactId, msgArray, 4, "edited_contact");

    } else if(activityType === "deleted_contact") {
      const msgArray = activityMessage.split(" ");
      if(msgArray[5].split(",").length > 1){
        msgArray[3] = msgArray[5].split(",").length;
        msgArray[4] = 'contacts';
        msgArray.splice(5, 1);
        setLogMessage(msgArray.toString(" ").replace(/,/g, ' '));
      } else {
        const deleteContactId = parseInt(msgArray[5], 10);
        getContactName(deleteContactId, msgArray, 5, "deleted_contact");
      }
    }

  }, [activityMessage, activityType, getContactInfo, isLoggedInUser, contactData, userIds]);

  return (
    <div ref={ref} className="sync-card-wrapper">
      {show && (
        <div className="sync-card">
          <div className="sync-status">
            <Tag color={color}>{status}</Tag>
          </div>
          <div className="sync-info">
            <Text className="cb-text-center cb-text-strong">
              {logMessage}{" "}
              {!isEmpty(errorLogs) && <SyncError errorLogs={errorLogs} />}
            </Text>
            <div className="sync-meta-info">
              <div className="sync-date">
                <CalendarOutlined /> {getDate(createdAt)}
              </div>
              {startTime !== null && endTime !== null && (
                <>
                  <div className="sync-start-time">
                    <ClockCircleOutlined /> {getTime(startTime)}
                  </div>
                  <div className="sync-from-to-arrow">
                    <ArrowRightOutlined />
                  </div>
                  <div className="sync-end-time">{getTime(endTime)}</div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default LogCard;
