//! User Files

import {
  FreeIcon,
  StartupIcon,
  BusinessIcon,
  CorporateIcon,
} from "assets/svg/pricing";

//!Below are the old plans, will remove once trial plan starts working
// export const plans = [
//   {
//     id: 1,
//     monthlyPlanId: 2,
//     yearlyPlanId: 2,
//     icon: <FreeIcon className="cb-svg-lg" />,
//     planName: "Free",
//     planKey: "free",
//     description: "Free plan with limited features",
//     monthlyPrice: 0,
//     yearlyPrice: 0,
//     yearlyPriceText: "Free",
//     monthlyPriceText: "Free",
//     members: "3 members / organization",
//     features: ["Email Support", "10 Shared Labels", "50 Contacts Per Label"],
//   },
//   {
//     id: 2,
//     monthlyPlanId: 5,
//     yearlyPlanId: 8,
//     icon: <StartupIcon className="cb-svg-lg" />,
//     planName: "Startup",
//     planKey: "startup",
//     description: "Startup plan with extra features",
//     monthlyPrice: 9,
//     yearlyPrice: 7,
//     monthlyPriceText: "$ 9 / month",
//     yearlyPriceText: "$ 7 / month",
//     members: "10 members / organization",
//     features: [
//       "Email Support",
//       "Permission Management",
//       "Unlimited Shared Contacts",
//       "Unlimited Shared Labels",
//       "Unlimited Contacts Per Label",
//     ],
//   },
//   {
//     id: 3,
//     monthlyPlanId: 3,
//     yearlyPlanId: 7,
//     icon: <BusinessIcon className="cb-svg-lg" />,
//     planName: "Business",
//     planKey: "business",
//     description: "Business plan with extra features",
//     monthlyPrice: 39,
//     yearlyPrice: 30,
//     monthlyPriceText: "$ 39 / month",
//     yearlyPriceText: "$ 30 / month",
//     members: "40 members / organization",
//     features: [
//       "Email Support",
//       "Permission Management",
//       "Unlimited Shared Contacts",
//       "Unlimited Shared Labels",
//       "Unlimited Contacts Per Label",
//       "Chat Support",
//     ],
//   },
//   {
//     id: 4,
//     monthlyPlanId: 1,
//     yearlyPlanId: 6,
//     icon: <CorporateIcon className="cb-svg-lg" />,
//     planName: "Corporate",
//     planKey: "corporate",
//     description: "Corporate plan with extra features",
//     monthlyPrice: 79,
//     yearlyPrice: 60,
//     monthlyPriceText: "$ 79 / month",
//     yearlyPriceText: "$ 60 / month",
//     members: "80 members / organization",
//     features: [
//       "Email Support",
//       "Permission Management",
//       "Unlimited Shared Contacts",
//       "Unlimited Shared Labels",
//       "Unlimited Contacts Per Label",
//       "Chat Support",
//     ],
//   },
// ];

export const oldPlans = [
  {
    id: 1,
    planId: 2,
    icon: <FreeIcon className="cb-svg-lg" />,
    planName: "Free",
    planKey: "free",
    description: "Free plan with limited features",
    price: 0,
    priceText: "Free",
    members: "3 members / organization",
    limit: 3,
    features: [
      "Email Support",
      "10 Shared Labels",
      "50 Contacts Per Label",
    ],
  },
  {
    id: 2,
    planId: 5,
    icon: <StartupIcon className="cb-svg-lg" />,
    planName: "Startup",
    planKey: "startupMonthly",
    description: "Startup plan with extra features",
    price: 9,
    priceText: "$ 9 / month",
    members: "10 members / organization",
    limit: 10,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
    ],
  },
  {
    id: 3,
    planId: 3,
    icon: <BusinessIcon className="cb-svg-lg" />,
    planName: "Business",
    planKey: "businessMonthly",
    description: "Business plan with extra features",
    price: 39,
    priceText: "$ 39 / month",
    members: "40 members / organization",
    limit: 40,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
  {
    id: 4,
    planId: 1,
    icon: <CorporateIcon className="cb-svg-lg" />,
    planName: "Corporate",
    planKey: "corporateMonthly",
    description: "Corporate plan with extra features",
    price: 79,
    priceText: "$ 79 / month",
    members: "80 members / organization",
    limit: 80,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
  {
    id: 5,
    planId: 8,
    icon: <StartupIcon className="cb-svg-lg" />,
    planName: "Startup",
    planKey: "startupYearly",
    description: "Startup plan with extra features",
    price: 7,
    priceText: "$ 7 / month",
    members: "10 members / organization",
    limit: 10,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
    ],
  },
  {
    id: 6,
    planId: 7,
    icon: <BusinessIcon className="cb-svg-lg" />,
    planName: "Business",
    planKey: "businessYearly",
    description: "Business plan with extra features",
    price: 30,
    priceText: "$ 30 / month",
    members: "40 members / organization",
    limit: 40,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
  {
    id: 7,
    planId: 6,
    icon: <CorporateIcon className="cb-svg-lg" />,
    planName: "Corporate",
    planKey: "corporateYearly",
    description: "Corporate plan with extra features",
    price: 60,
    priceText: "$ 60 / month",
    members: "80 members / organization",
    limit: 80,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
]

export const newPlans = [
  {
    id: 1,
    monthlyPlanId: 9,
    yearlyPlanId: 9,
    icon: <FreeIcon className="cb-svg-lg" />,
    planName: "No Plan",
    planKey: "noplan",
    description: "No plan with only view permission",
    monthlyPrice: 0,
    yearlyPrice: 0,
    monthlyPriceText: "$ 0 / month",
    yearlyPriceText: "$ 0 / month",
    members: "0 members / organization",
    limit: 0,
    features: [
      "Email Support",
      "00 Shared Labels",
      "00 Contacts Per Label",
    ],
  },
  {
    id: 2,
    planId: 14,
    icon: <StartupIcon className="cb-svg-lg" />,
    planName: "Startup",
    planKey: "startupMonthlyV2",
    description: "Startup plan with extra features",
    price: 9,
    priceText: "$ 9 / month",
    members: "10 members / organization",
    limit: 10,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
    ],
  },
  {
    id: 3,
    planId: 12,
    icon: <BusinessIcon className="cb-svg-lg" />,
    planName: "Business",
    planKey: "businessMonthlyV2",
    description: "Business plan with extra features",
    price: 39,
    priceText: "$ 39 / month",
    members: "40 members / organization",
    limit: 40,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
  {
    id: 4,
    planId: 10,
    icon: <CorporateIcon className="cb-svg-lg" />,
    planName: "Corporate",
    planKey: "corporateMonthlyV2",
    description: "Corporate plan with extra features",
    price: 79,
    priceText: "$ 79 / month",
    members: "80 members / organization",
    limit: 80,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
  {
    id: 5,
    planId: 15,
    icon: <StartupIcon className="cb-svg-lg" />,
    planName: "Startup",
    planKey: "startupYearlyV2",
    description: "Startup plan with extra features",
    price: 7,
    priceText: "$ 7 / month",
    members: "10 members / organization",
    limit: 10,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
    ],
  },
  {
    id: 6,
    planId: 13,
    icon: <BusinessIcon className="cb-svg-lg" />,
    planName: "Business",
    planKey: "businessYearlyV2",
    description: "Business plan with extra features",
    price: 30,
    priceText: "$ 30 / month",
    members: "40 members / organization",
    limit: 40,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
  {
    id: 7,
    planId: 11,
    icon: <CorporateIcon className="cb-svg-lg" />,
    planName: "Corporate",
    planKey: "corporateYearlyV2",
    description: "Corporate plan with extra features",
    price: 60,
    priceText: "$ 60 / month",
    members: "80 members / organization",
    limit: 80,
    features: [
      "Email Support",
      "Permission Management",
      "Unlimited Shared Contacts",
      "Unlimited Shared Labels",
      "Unlimited Contacts Per Label",
      "Chat Support",
    ],
  },
]