import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($userAccessCode: String!) {
    login(userAccessCode: $userAccessCode) {
      data {
        serverToken
        domainName
        userName
        userEmail
        profilePicture
        isAdmin
        userId
        isConsentGiven
        isOldUser
        migrationStatus
        isUpdatedSubscription
        hasLoggedInBefore
        autoSyncTime
      }
      statusCode
      statusMessage
    }
  }
`;
