//! Ant Imports

import Button from "antd/lib/button";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

//! User Files

import { getMarketPlaceURL } from "common/utils";

function MarketPlaceApproval({ domain, loading, refetch }) {
  const marketPlaceURL = getMarketPlaceURL(domain);
  const marketplaceAppLink =
    "https://admin.google.com/ac/apps/gmail/marketplace/appdetails/406787645084";

  const handleMPInstallation = () => {
    window.location.href = marketPlaceURL;
  };

  const handleRefresh = () => {
    refetch();
  };

  return (
    <div className="marketplace cb-height-inherit">
      <div className="marketplace-div">
        <Title level={3}>
          You need to <i>Domain Install</i> - ContactBook from the Google Workspace Marketplace
        </Title>
        <div className="cta-btn">
          <Button loading={loading} onClick={handleRefresh}>
            {loading ? "Refreshing" : "Refresh"} Status
          </Button>
          <Button type="primary" onClick={handleMPInstallation}>
            Install Contactbook
          </Button>
        </div>
        <Text type="secondary" className="delegation-note">
          If you have already installed the app, you may have to wait for a few
          seconds and click on the refresh button.
        </Text>
        <Text type="secondary" className="delegation-note">
          If you are still having issues, please{" "}
          <a href={marketplaceAppLink} target="_blank" rel="noreferrer">
            uninstall the app by clicking on this link
          </a>{" "}
          and then <span className="cb-text-strong">DELETE APP</span> from Google Admin console.
          Once done, please install it again from above.
        </Text>
      </div>
    </div>
  );
}

export default MarketPlaceApproval;
