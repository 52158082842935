import { v4 as uuidv4 } from "uuid";

//! Ant Imports

import Tooltip from "antd/lib/tooltip";

//! User Files

import { ReactComponent as QuestionIcon } from "assets/svg/logs/question.svg";
import { getMessageByType } from "common/utils";

function SyncError({ errorLogs }) {
  const messages = getMessageByType(errorLogs);
  const text = (
    <span className="cb-tooltip-sync-logs-error">
      {messages.map((message) => {
        const id = uuidv4();
        return <p key={id}>{message}</p>;
      })}
    </span>
  );
  return (
    messages.length > 0 && (
      <Tooltip placement="bottom" title={text} className="contact-tooltip">
        <QuestionIcon className="cb-svg-sm log-error" />
      </Tooltip>
    )
  );
}

export default SyncError;
