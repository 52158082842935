import { createContext, useReducer } from "react";
import findIndex from "lodash/findIndex";
//! User Files

import * as ActionTypes from "common/actionTypes";
import client from "apollo";
import api from "common/api";
import { PLAN, TOKEN, USER, USER_ID } from "common/constants";

const getLoggedInUser = () => {
  let loggedInUser = localStorage.getItem(USER);
  loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;
  return loggedInUser;
};

const getUserId = () => {
  return localStorage.getItem(USER_ID)
    ? parseInt(localStorage.getItem(USER_ID))
    : "";
};

const getCurrentPlan = () => {
  let domainPlan = localStorage.getItem(PLAN);
  domainPlan = domainPlan ? JSON.parse(domainPlan) : {};
  return domainPlan;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  userId: getUserId(),
  authToken: localStorage.getItem(TOKEN),
  authenticated: false,
  planData: {},
  selectedGroup: {},
  domainUsers: [],
  isDomainUsersError: false,
  syncInProgress: false, //! By default TRUE - In case user click on sync button before API response come.
  isSyncStatusFetchError: false,
  domainSharedUsers: [],
  contactsFetchLimit: 50,
  delegationGiven: false,
  userRights: [],
};

const reducer = (state, action) => {
  let cbUser = getLoggedInUser();
  let cbUserObj = {};
  switch (action.type) {
    //! USER
    case ActionTypes.SET_CURRENT_USER:
      const user = action.data || {};
      localStorage.setItem(
        USER,
        user && Object.keys(user).length ? JSON.stringify(user) : null
      );
      return { ...state, currentUser: { ...user } };
    case ActionTypes.SET_USER_ID:
      localStorage.setItem(USER_ID, action.data);
      return { ...state, userId: action.data };
    case ActionTypes.SET_AUTHENTICATED:
      return { ...state, authenticated: action.data };
    case ActionTypes.SET_TOKEN:
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    //! Onboarding Done
    case ActionTypes.SET_ONBOARDING_DONE:
      const onboardUser = getLoggedInUser();
      const userObjWithOnboardingDone = {
        ...onboardUser,
        hasLoggedInBefore: true,
      };
      localStorage.setItem(USER, JSON.stringify(userObjWithOnboardingDone));
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          hasLoggedInBefore: true,
        },
      };
    //! Domain Plan
    case ActionTypes.SET_DOMAIN_PLAN:
      const plan = action.data || {};
      localStorage.setItem(PLAN, JSON.stringify(plan));
      return {
        ...state,
        planData: plan,
      };
    //! Domain Users List
    case ActionTypes.SET_DOMAIN_USERS:
      return { ...state, domainUsers: action.data };
    case ActionTypes.SET_DOMAIN_USERS_FETCH_ERROR:
      return { ...state, isDomainUsersError: action.data };
    //! Domain Shared Users List
    case ActionTypes.SET_DOMAIN_SHARED_USERS:
      return { ...state, domainSharedUsers: action.data };
    //! Sync Status
    case ActionTypes.SET_SYNC_STATUS:
      return { ...state, syncInProgress: action.data };
    case ActionTypes.SET_SYNC_STATUS_FETCH_ERROR:
      return { ...state, isSyncStatusFetchError: action.data };
    //! GROUP
    case ActionTypes.SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.data,
      };
    //! User Consent
    case ActionTypes.SET_CONSENT_GIVEN:
      cbUserObj = {
        ...cbUser,
        isConsentGiven: true,
      };
      localStorage.setItem(USER, JSON.stringify(cbUserObj));
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isConsentGiven: true,
        },
      };
    //! Migration Done
    case ActionTypes.SET_MIGRATION_STATUS:
      cbUserObj = {
        ...cbUser,
        migrationStatus: action.data,
      };
      localStorage.setItem(USER, JSON.stringify(cbUserObj));
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          migrationStatus: action.data,
        },
      };
    //! Auto Sync Preference
    case ActionTypes.SET_AUTO_SYNC_PREFERENCE:
      cbUserObj = {
        ...cbUser,
        autoSyncTime: action.data,
      };
      localStorage.setItem(USER, JSON.stringify(cbUserObj));
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          autoSyncTime: action.data,
        },
      };
    //! Delegation
    case ActionTypes.SET_DELEGATION_FLAG:
      return {
        ...state,
        delegationGiven: action.data,
      };
    //! LOGOUT
    case ActionTypes.LOGOUT:
      delete api.defaults.headers.common.Authorization;
      localStorage.clear();
      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        currentUser: {},
      };
    //! User Permission
    case ActionTypes.SET_USER_RIGHTS:
      const userId = action.data.userId;

      const isExists = findIndex(state.userRights, {userId: userId});
      if(isExists !== -1 ) {
        state.userRights[isExists] = action.data;
        return {...state };
      } else {
        return {
          ...state,
          userRights: [...state.userRights, action.data]
        }
      }
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    return localStorage.getItem(TOKEN) || null;
  };

  // eslint-disable-next-line
  const getCurrentUser = () => {
    return localStorage.getItem(USER)
      ? JSON.parse(localStorage.getItem(USER))
      : {};
  };

  const initializeAuth = (authToken) => {
    const token = authToken || getToken();
    const user = getCurrentUser();
    const userId = getUserId();
    const plan = getCurrentPlan();
    if (token) {
      api.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
        userid: userId,
      };
      dispatch({ type: ActionTypes.SET_TOKEN, data: token });
      dispatch({ type: ActionTypes.SET_AUTHENTICATED, data: true });
      dispatch({ type: ActionTypes.SET_CURRENT_USER, data: user });
      dispatch({ type: ActionTypes.SET_USER_ID, data: userId });
      dispatch({ type: ActionTypes.SET_DOMAIN_PLAN, data: plan });
    }
  };

  const value = {
    state,
    dispatch,
    initializeAuth,
    getToken,
    getCurrentPlan,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
