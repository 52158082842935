import { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";

//! Ant Imports

import TimePicker from "antd/lib/time-picker";
import Tooltip from "antd/lib/tooltip";
import Title from "antd/lib/typography/Title";
import Button from "antd/lib/button";
import Form from "antd/lib/form/Form";

//! User Files

import * as ActionTypes from "common/actionTypes";
import { SERVER_ERROR } from "common/constants";
import { toast } from "common/utils";
import { AppContext } from "AppContext";
import useStatusCode from "common/hooks/useStatusCode";
import { ReactComponent as QuestionIcon } from "assets/svg/logs/question.svg";

//! GraphQL

import { SET_AUTO_SYNC_TIME } from "../graphql/Mutations";

function SchedulerContent({
  prev = () => {},
  fromDashboard = false,
  handleCancel = () => {},
}) {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);

  const [selectedTime, setSelectedTime] = useState(null);
  const [checkAndHandleStatusCode] = useStatusCode();
  const autoSyncTime = currentUser?.autoSyncTime;
  const [setAutoSyncTime, { loading }] = useMutation(SET_AUTO_SYNC_TIME, {
    onCompleted({ autoSyncTime }) {
      const { statusCode } = autoSyncTime;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        toast({
          message: "Something went wrong. Please try again!",
          type: "error",
        });
        return;
      } else {
        dispatch({
          type: ActionTypes.SET_AUTO_SYNC_PREFERENCE,
          data: selectedTime,
        });
        toast({
          message: "Auto Sync scheduler has been set successfully",
          type: "success",
        });
        handleCancel();
      }
    },
    onError() {
      toast({
        message: "Something went wrong. Please try again!",
        type: "error",
      });
    },
  });

  const onFinish = (values) => {
    const { time } = values;
    const userTimeGeneric = moment(time).format();
    setSelectedTime(userTimeGeneric);
    if (userTimeGeneric !== autoSyncTime) {
      setAutoSyncTime({
        variables: { time: userTimeGeneric },
      });
    } else {
      toast({
        message: "The selected sync time is same as previous",
        type: "info",
      });
    }
  };

  const defaultTime = autoSyncTime ? moment(autoSyncTime) : null;
  const text = (
    <span className="cb-tooltip-description">
      ContactBook syncs with your Google Contacts account once a day. Please
      select your preferred time to sync Google Contacts with ContactBook. We
      prefer that you keep midnight time.
    </span>
  );

  return (
    <div className="cb-scheduler-content-wrapper">
      <Title level={3}>
        Select Auto-Sync Time
        <Tooltip
          placement="bottom"
          title={text}
          className="cb-scheduler-tooltip"
        >
          <QuestionIcon className="cb-svg-sm cb-cursor log-error" />
        </Tooltip>
      </Title>
      <Form
        name="basic"
        onFinish={onFinish}
        className="cb-time-picker-form"
        initialValues={{
          time: defaultTime,
        }}
      >
        <Form.Item
          name="time"
          rules={[
            {
              required: true,
              message: "Please select the time!",
            },
          ]}
        >
          <TimePicker
            autoFocus
            placeholder="Select Time"
            className="cb-time-picker"
            format="HH:mm"
            showNow={false}
            popupClassName="cb-time-picker-popup"
          />
        </Form.Item>
        <Form.Item>
          <div
            className={`cb-schedule-btn ${
              fromDashboard ? "cb-btn-dashboard" : "cb-btn-header"
            }`}
          >
            {fromDashboard && <Button onClick={prev}>Previous</Button>}
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? "Scheduling" : " Schedule Sync"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SchedulerContent;
