import gql from "graphql-tag";

export const START_SYNC = gql`
  mutation startSync($userId: Int!, $isAdmin: Boolean!) {
    startSync(userId: $userId, isAdmin: $isAdmin) {
      data
      statusCode
      statusMessage
    }
  }
`;
