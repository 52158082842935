import React from "react";
//! Ant Imports

import Modal from "antd/lib/modal";

const DowngradeModal = ({ visible, closePopup, planStatus }) => {
  let title = <h2>You can not switch to this plan</h2>;
  let content = '';

  if(planStatus === "trialing") {
    content = "You can not switch to another plan during your trial period. If you want to share contacts with more users, please cancel subscription and upgrade to a new plan."
  } else {
    content = "Number of shared users in this account are more than the users allowed in this plan.You will need to reduce the number of shared users in order to switch to this plan."
  }

  return (
    <Modal
      className="cb-pricing-modal"
      title={title}
      visible={visible}
      onCancel={closePopup}
      centered
      footer={null}
      width={550}
    >
      {content}
    </Modal>);
}

export default DowngradeModal;
