//! User Files

import {
  AccountIcon,
  HelpIcon,
  // HistoryIcon,
  UpgradeIcon,
  LogoutIcon,
} from "assets/svg/menu";

export const roleBasedMenuItems = {
  ADMIN: [
    {
      link: "/account",
      label: "Account",
      icon: <AccountIcon className="cb-svg" />,
    },
    {
      link: "/pricing",
      label: "Upgrade",
      icon: <UpgradeIcon className="cb-svg" />,
    },
    {
      link: "/support",
      label: "Support",
      icon: <HelpIcon className="cb-svg" />,
    },
    {
      link: "/logout",
      label: "Logout",
      icon: <LogoutIcon className="cb-svg" />,
    },
  ],
  USER: [
    {
      link: "/support",
      label: "Support",
      icon: <HelpIcon className="cb-svg" />,
    },
    {
      link: "/logout",
      label: "Logout",
      icon: <LogoutIcon className="cb-svg" />,
    },
  ],
};
