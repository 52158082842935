import gql from "graphql-tag";

export const GET_GROUPS_LIST = gql`
  query getGroupList($userId: Int!, $listType: String!) {
    getGroupList(userId: $userId, listType: $listType) {
      data {
        groupName
        groupId
        googleGroupId
        groupMappedId
        groupAdminId
        isOwner
        contactCount
        planStatus
      }
      statusCode
      statusMessage
    }
  }
`;
