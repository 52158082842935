import gql from "graphql-tag";

export const SET_USER_CONSENT = gql`
  mutation consentGiven($userId: Int!) {
    consentGiven(userId: $userId) {
      data
      statusCode
      statusMessage
    }
  }
`;

export const SET_ONBOARDING_DONE = gql`
  mutation completeOnboarding {
    completeOnboarding {
      data
      statusCode
      statusMessage
    }
  }
`;

export const SET_USER_PERMISSION = gql`
  mutation updateGroupSharingAccess($groupSharingId: Int!, $accessType: Boolean!) {
    updateGroupSharingAccess(groupSharingId: $groupSharingId, accessType: $accessType) {
      data
      statusMessage
    }
  }
`