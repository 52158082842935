import { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

//! User Files

import { AppContext } from "AppContext";
import { consentScreen } from "common/utils";

const Login = () => {
  const { state } = useContext(AppContext);

  useEffect(() => {
    window.location.href = consentScreen;
  }, []);

  if (state.authenticated) return <Redirect to="/" />;
  return <div />;
};

export default Login;
