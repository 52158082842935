import React from "react";

//! Ant Imports
import Modal from "antd/lib/modal";
import Text from "antd/lib/typography/Text";

const SwitchPlanConfirmPopup = ({ confirmPopup, switchPlan, closeSwitchPlanPopup }) => {
  let title = <h2>Are you sure you want to switch the plan?</h2>;
  let content = '';

  if(confirmPopup === true) {
    content = <div>
      <ul className="cb-cancel-detail">
        <li className="cb-cancel-detail-list">You are about to change the plan. You will be able to share contacts with users based on the selected plan.</li>
        <li className="cb-cancel-detail-list"><b>Note that the charges will be prorated based on the selected plan. Your next billing cycle will reflect the changes in amount.</b></li>
      </ul>
      </div>
  }

  return (
    <Modal
      className="cb-cancel-subscription-modal"
      title={title}
      visible={confirmPopup}
      onCancel={closeSwitchPlanPopup}
      centered
      width={550}
      onOk={switchPlan}
    >
      <Text>
        {content}
      </Text>
    </Modal>);
}

export default SwitchPlanConfirmPopup;
