import gql from "graphql-tag";

export const START_MIGRATION = gql`
  mutation startMigration($userEmail: String!) {
    startMigration(userEmail: $userEmail) {
      data
      statusCode
      statusMessage
    }
  }
`;
