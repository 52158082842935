import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import sortBy from "lodash/sortBy";

//! Ant Imports

import Card from "antd/lib/card";
import Table from "antd/lib/table";
import Tag from "antd/lib/tag";
import Tooltip from "antd/lib/tooltip";

//! User Files

import { AppContext } from "AppContext";
import useTableSearch from "common/hooks/useTableSearch";

function SubscribedUsers() {
  const {
    state: { domainSharedUsers, currentUser },
  } = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [getColumnSearchProps] = useTableSearch();
  const name = currentUser?.userName;
  const email = currentUser?.userEmail;
  useEffect(() => {
    setUsers([
      {
        name: `${name} (You)`,
        email,
        groupNames: [],
      },
      ...domainSharedUsers,
    ]);
  }, [domainSharedUsers, name, email]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Labels",
      key: "groupNames",
      dataIndex: "groupNames",
      ellipsis: {
        showTitle: false,
      },
      render: (tags) => {
        const sortedTags = sortBy(tags);
        const renderTags = sortedTags?.map((tag) => {
          const id = uuidv4();
          return (
            <Tag color="default" className="label-name" key={id}>
              {tag.toUpperCase()}
            </Tag>
          );
        });
        return (
          <Tooltip
            color="white"
            placement="topLeft"
            className="cb-label-tooltip-wrapper"
            overlayClassName="cb-tooltip-card cb-scrollbar"
            title={<div className="cb-label-tag">{renderTags}</div>}
          >
            {renderTags}
          </Tooltip>
        );
      },
    },
  ];

  const totalUsers = domainSharedUsers?.length + 1;
  const title = (
    <span className="cb-text-strong">Subscribed Users ({totalUsers})</span>
  );
  return (
    <Card title={title}>
      <Table
        className="subscribed-user-table"
        columns={columns}
        dataSource={users}
        pagination={false}
        sticky
        scroll={{ x: 500 }}
        rowKey="email"
      />
    </Card>
  );
}

export default SubscribedUsers;
