//! Ant Imports

import Result from "antd/lib/result";

function DataFetchError() {
  return (
    <div className="cb-data-fecth-error-wrapper">
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
      />
    </div>
  );
}

export default DataFetchError;
