//! Ant Imports

import Modal from "antd/lib/modal";

//! User Files

import SchedulerContent from "./components/SchedulerContent";

function Scheduler({ visible, handleCancel }) {
  return (
    <Modal
      destroyOnClose
      className="cb-scheduler-modal"
      maskClosable={false}
      keyboard={false}
      visible={visible}
      centered
      onCancel={handleCancel}
      footer={null}
    >
      <SchedulerContent handleCancel={handleCancel} />
    </Modal>
  );
}

export default Scheduler;
