export const MIGRATION_TEXT = {
  start_content: [
    "Once you start migration, our system will look for all labels/contacts your Google account has shared from old version and the users they are shared with.",
  ],
  steps: [
    "This is important! Our system will KEEP original labels and contacts in your Google account as it is and DELETE the labels and contacts from Google Contacts of all shared users. (This is to avoid duplication of labels/contacts in Google Contacts of shared users when they are shared again from new system)",
    "Once migration is completed, your account will start syncing automatically. Upon successful sync, all your contacts and labels from Google Contacts will reflect in our new system. You can then start sharing labels and contacts with your users.",
  ],
  end_content: [
    "Please note that after migration your shared users will lose access to contacts that are shared with them in the previous version. You will have to share with them again in the new version. Please share contacts with them immediately so they get back all the contacts",
  ],
};
