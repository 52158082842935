import { useContext } from "react";

//! Ant Imports

import Button from "antd/lib/button";
import Typography from "antd/lib/typography";

//! User Files

import { AppContext } from "AppContext";

const { Title, Text } = Typography;

//!There are comments in this files, will remove once plan stuff works rightly
function PricingCard({ plan, checked, handleSubscription, cancelSubscription, updatePlan, planDetails, updateLoading }) {
  const {
    icon,
    // monthlyPlanId,
    // yearlyPlanId,
    planName,
    planKey,
    description,
    // yearlyPriceText,
    // monthlyPriceText,
    members,
    features,
    // monthlyPrice,
    // yearlyPrice,
    priceText,
    planId,
    price
  } = plan;

  const {
    state: { planData },
  } = useContext(AppContext);
  const currentUserPlanKey = planData?.planKey;
  const renderFeatures = features?.map((feature) => (
    <Text type="secondary" className="cb-text-center" key={feature}>
      {feature}
    </Text>
  ));
  let planSlug = '';
  let buttonText = 'Start Free Trial';
  const isDefaultPlan = planKey === "free" || planKey === "noplan";

  planSlug = planKey;

  if((planData?.planId === 9 && planData?.status !== "active")|| planData?.planId === 2 || planData?.status === "deleted") {
    buttonText = "Subscribe";
  } else if(!(planId >= 10 && planData?.planId <= 15)) {
    buttonText = "Switch Plan";
  }

  const isCurrentPlan = currentUserPlanKey === planSlug && planData?.status !== "deleted";
  const isPlanDeleted = planData?.paddleData?.status === "deleted";
  return (
    <>
      <div className="pricing-plan-card">
        <div className="pricing-plan-card-svg">{icon}</div>
        <div className="pricing-plan-card-name">
          <Title level={4} className="cb-text-center cb-text-strong">
            {planName}
          </Title>
        </div>
        <div className="pricing-plan-card-description">
          <Text type="secondary" className="cb-text-center">
            {description}
          </Text>
        </div>
        <div className="pricing-plan-card-price">
          <Title level={4} className="cb-text-center">
            {priceText}
          </Title>
        </div>
        <div className="pricing-plan-card-org-members">
          <Text type="secondary" className="cb-text-center cb-text-strong">
            {members}
          </Text>
        </div>
        <div className="pricing-plan-card-features">{renderFeatures}</div>
      </div>
      <div className="pricing-plan-button">
        <Button
          disabled={isDefaultPlan || isCurrentPlan || updateLoading}
          type={isCurrentPlan ? "danger" : "primary"}
          onClick={
            () => buttonText === "Switch Plan" ?
            updatePlan(plan, planId, planSlug, price) : handleSubscription(plan, planId, planSlug)
          }
          loading={planDetails?.selectedPlanId === planId && updateLoading}
        >
          {isDefaultPlan
            ? "Default Plan"
            : isPlanDeleted
            ? "Subscribe"
            : isCurrentPlan
            ? "Current Plan"
            : buttonText}
        </Button>
      </div>
    </>
  );
}

export default PricingCard;
