import gql from "graphql-tag";

export const SET_AUTO_SYNC_TIME = gql`
  mutation autoSyncTime($time: String!) {
    autoSyncTime(time: $time) {
      data
      statusCode
      statusMessage
    }
  }
`;
