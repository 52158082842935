import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Redirect, useHistory } from "react-router-dom";

//! User Files

import * as ActionTypes from "common/actionTypes";
import { AppContext } from "AppContext";
import { toast, useRouteQuery } from "common/utils";
import Loading from "components/Loading";
import ServerError from "components/ServerError";
import useStatusCode from "common/hooks/useStatusCode";
import { ROUTES, SERVER_ERROR } from "common/constants";

//! GraphQL

import { LOGIN } from "./graphql/Mutations";

function GoogleAuthCode() {
  const { push } = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const query = useRouteQuery();
  const [checkAndHandleStatusCode] = useStatusCode();

  const [loginUser, { loading, error }] = useMutation(LOGIN, {
    onCompleted({ login }) {
      const { data, statusCode } = login;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR || !data.serverToken) {
        dispatch({ type: ActionTypes.LOGOUT });
        push(ROUTES.LOGIN);
      } else {
        toast({
          message: "Successfully Logged In",
          type: "success",
        });
        dispatch({ type: ActionTypes.SET_TOKEN, data: data.serverToken });
        dispatch({ type: ActionTypes.SET_CURRENT_USER, data });
        dispatch({ type: ActionTypes.SET_USER_ID, data: data.userId });
        dispatch({ type: ActionTypes.SET_AUTHENTICATED, data: true });
      }
    },
  });
  useEffect(() => {
    const userAccessCode = query.get("code");
    const accessDenied = query.get("error");
    if (accessDenied) {
      push(ROUTES.LOGIN);
    } else {
      loginUser({
        variables: {
          userAccessCode,
        },
      });
    }
    // eslint-disable-next-line
  }, []);
  if (state.authenticated) return <Redirect to="/" />;
  if (loading) return <Loading />;
  if (error) return <ServerError />;
  return <div />;
}

export default GoogleAuthCode;
