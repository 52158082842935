import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_PLAN = gql`
  mutation updatePlan($data: UpdatePlanInput!) {
    updatePlan(data: $data) {
      data
    }
  }
`;
