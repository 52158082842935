import { useContext, useEffect, useState } from "react";
import sortBy from "lodash/sortBy";
import {
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";


//! Ant Imports

import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";

//! Ant Icons

import SyncOutlined from "@ant-design/icons/SyncOutlined";
import { AppContext } from "AppContext";

//! User Files

import * as ActionTypes from "common/actionTypes";
import useStatusCode from "common/hooks/useStatusCode";
import { SERVER_ERROR } from "common/constants";
import { toast } from "common/utils";
import { MIGRATION_STATUS } from "common/messages/migrationStatus";
import { SYNC_STATUS } from "common/messages/syncStatus";

//! GraphQL

import { START_SYNC } from "./graphql/Mutations";
import { GET_SYNC_STATUS_SUBSCRIPTION } from "./graphql/Subscriptions";
import { GET_SYNC_STATUS } from "./graphql/Queries";
import { GET_ORGANIZATION_SHARED_USERS } from "modules/account/graphql/Queries";
import UpgradePopup from "components/UpgradePopup";


function Sync() {
  const {
    state: { syncInProgress, currentUser, userId, planData },
    dispatch,
  } = useContext(AppContext);
  const {
    YOUR_SYNC_IS_ALREADY_IN_PROGRESS,
    YOUR_SYNC_HAS_BEEN_STARTED,
    SYNC_HAS_BEEN_COMPLETED,
    MIGRATION_COMPLETED_YOU_CAN_NOW_SYNC_YOUR_DATA,
    YOUR_MIGRATION_IS_ALREADY_IN_PROGRESS,
  } = SYNC_STATUS;
  const { MIGRATION_STATUS_COMPLETED } = MIGRATION_STATUS;
  const [isRotating, setIsRotating] = useState(false);
  const [checkAndHandleStatusCode] = useStatusCode();
  const [upgradePopup, openUpgradePopup] = useState(false);

  const isOldUser = currentUser?.isOldUser;
  const isAdmin = currentUser?.isAdmin;
  const domainName = currentUser?.domainName;
  const isConsentGiven = currentUser?.isConsentGiven;
  const hasLoggedInBefore = currentUser?.hasLoggedInBefore;
  const popupText = isAdmin ? " the plan to continue with the sync" :
  "Please contact your Admin to subscribe the plan to continue with the sync.";

  const shouldSyncButtonDisabledForOldUser = isOldUser
    ? currentUser.migrationStatus !== MIGRATION_STATUS_COMPLETED
    : false;

  const [startSync, { loading: mutationLoading }] = useMutation(START_SYNC, {
    context: {
      headers: {
        initialsync: !hasLoggedInBefore,
      },
    },
    onCompleted({ startSync }) {
      const { statusCode, statusMessage } = startSync;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        setIsRotating(false);
        toast({
          message: "Something went wrong",
          type: "error",
        });
        return;
      } else {
        switch (statusMessage) {
          case YOUR_SYNC_IS_ALREADY_IN_PROGRESS:
            toast({
              message: YOUR_SYNC_IS_ALREADY_IN_PROGRESS,
              type: "info",
            });
            break;
          case YOUR_SYNC_HAS_BEEN_STARTED:
            toast({
              message: YOUR_SYNC_HAS_BEEN_STARTED,
              type: "success",
            });
            break;
          default:
            break;
        }
        dispatch({ type: ActionTypes.SET_SYNC_STATUS, data: true });
      }
    },
    onError() {
      setIsRotating(false);
      toast({
        message: "Error occurred while starting sync. Please try again later!",
        type: "error",
      });
    },
  });

  const handleSyncButtonClick = () => {
    if(planData?.planId === null || planData?.status === "deleted") {
      openUpgradePopup(true);
    } else {
      setIsRotating(true);
      startSync({
        variables: { userId, isAdmin },
      });
    }
  };

  const [getSyncStatus, { data: syncData }] = useLazyQuery(GET_SYNC_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onError(error) {
      dispatch({
        type: ActionTypes.SET_SYNC_STATUS_FETCH_ERROR,
        data: error,
      });
    },
  });

  const [
    getDomainSharedUsersList,
    { data: domainSharedUsersData },
  ] = useLazyQuery(GET_ORGANIZATION_SHARED_USERS, {
    fetchPolicy: "network-only",
    onError(error) {
      dispatch({
        type: ActionTypes.SET_DOMAIN_SHARED_USERS,
        data: [],
      });
    },
  });

  const { data: syncSubscriptionData } = useSubscription(
    GET_SYNC_STATUS_SUBSCRIPTION,
    {
      // shouldResubscribe: true,
      variables: { userId },
    }
  );

  useEffect(() => {
    if (isConsentGiven) {
      if (isOldUser) {
        if (currentUser.migrationStatus === MIGRATION_STATUS_COMPLETED) {
          getSyncStatus({ variables: { userId } });
          getDomainSharedUsersList({
            variables: { domainName },
          });
        }
      } else {
        getSyncStatus({ variables: { userId } });
        getDomainSharedUsersList({
          variables: { domainName },
        });
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (syncData?.syncStatus) {
      const { syncInProgress, statusCode } = syncData.syncStatus;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        dispatch({
          type: ActionTypes.SET_SYNC_STATUS_FETCH_ERROR,
          data: true,
        });
        dispatch({ type: ActionTypes.SET_SYNC_STATUS, data: false });
        return;
      }
      setIsRotating(syncInProgress);
      dispatch({ type: ActionTypes.SET_SYNC_STATUS, data: syncInProgress });
    }
    // eslint-disable-next-line
  }, [syncData]);

  useEffect(() => {
    if (domainSharedUsersData?.getOrganizationSharedUser) {
      const {
        data,
        statusCode,
      } = domainSharedUsersData.getOrganizationSharedUser;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        dispatch({ type: ActionTypes.SET_DOMAIN_SHARED_USERS, data: [] });
        return;
      } else {
        dispatch({
          type: ActionTypes.SET_DOMAIN_SHARED_USERS,
          data: sortBy(data, "email"),
        });
      }
    }
    // eslint-disable-next-line
  }, [domainSharedUsersData]);

  useEffect(() => {
    if (syncSubscriptionData?.checkSyncStatus) {
      const {
        syncInProgress,
        statusCode,
        statusMessage,
      } = syncSubscriptionData.checkSyncStatus;
      const status = checkAndHandleStatusCode(statusCode);
      if (status === SERVER_ERROR) {
        toast({
          message: "Something went wrong. Please sync again",
          type: "error",
        });
        dispatch({
          type: ActionTypes.SET_SYNC_STATUS_FETCH_ERROR,
          data: true,
        });
        dispatch({ type: ActionTypes.SET_SYNC_STATUS, data: false });
        return;
      }
      if (statusCode === 200) {
        dispatch({ type: ActionTypes.SET_SYNC_STATUS, data: syncInProgress });
        switch (statusMessage) {
          case YOUR_SYNC_IS_ALREADY_IN_PROGRESS:
            toast({
              message: YOUR_SYNC_IS_ALREADY_IN_PROGRESS,
              type: "info",
            });
            break;
          case SYNC_HAS_BEEN_COMPLETED:
            setIsRotating(false);
            toast({
              message: `${SYNC_HAS_BEEN_COMPLETED}, if changes are not reflected, please try again after sometime`,
              type: "success",
            });
            if (hasLoggedInBefore) {
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
            break;
          case YOUR_MIGRATION_IS_ALREADY_IN_PROGRESS:
            toast({
              message: YOUR_MIGRATION_IS_ALREADY_IN_PROGRESS,
              type: "info",
            });
            break;
          case MIGRATION_COMPLETED_YOU_CAN_NOW_SYNC_YOUR_DATA:
            dispatch({
              type: ActionTypes.SET_MIGRATION_STATUS,
              data: MIGRATION_STATUS_COMPLETED,
            });
            toast({
              message: MIGRATION_COMPLETED_YOU_CAN_NOW_SYNC_YOUR_DATA,
              type: "success",
            });
            break;
          default:
            break;
        }
      }
    }
    // eslint-disable-next-line
  }, [syncSubscriptionData]);
  const title = (
    <span className="cb-tooltip-description">
      {syncInProgress ? "Sync is already in progress" : "Click to sync"}
    </span>
  );
  const renderSyncButton = (
    <div className="sync-wrapper">
      <Tooltip placement="bottom" title={title}>
        <Button
          disabled={
            syncInProgress ||
            mutationLoading ||
            shouldSyncButtonDisabledForOldUser
          }
          className="sync-btn"
          type="primary"
          shape="circle"
          onClick={handleSyncButtonClick}
          icon={<SyncOutlined className="sync-icon" spin={isRotating} />}
        />
      </Tooltip>
    </div>
  );

  return (
    <>
      <UpgradePopup openUpgradePopup={openUpgradePopup} visible={upgradePopup} isAdmin={isAdmin} popupText={popupText} />
      { renderSyncButton }
    </>
  );
}

export default Sync;
