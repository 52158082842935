export const getPaddleProductId = (planKey) => {
  let productId;
  switch (planKey) {
    case "startupMonthly":
      productId = process.env.REACT_APP_STARTUP_MONTHLY;
      break;
    case "startupYearly":
      productId = process.env.REACT_APP_STARTUP_YEARLY;
      break;
    case "businessMonthly":
      productId = process.env.REACT_APP_BUSINESS_MONTHLY;
      break;
    case "businessYearly":
      productId = process.env.REACT_APP_BUSINESS_YEARLY;
      break;
    case "corporateMonthly":
      productId = process.env.REACT_APP_CORPORATE_MONTHLY;
      break;
    case "corporateYearly":
      productId = process.env.REACT_APP_CORPORATE_YEARLY;
      break;
    case "startupMonthlyV2":
      productId = process.env.REACT_APP_STARTUP_MONTHLY_V2;
      break;
    case "startupYearlyV2":
      productId = process.env.REACT_APP_STARTUP_YEARLY_V2;
      break;
    case "businessMonthlyV2":
      productId = process.env.REACT_APP_BUSINESS_MONTHLY_V2;
      break;
    case "businessYearlyV2":
      productId = process.env.REACT_APP_BUSINESS_YEARLY_V2;
      break;
    case "corporateMonthlyV2":
      productId = process.env.REACT_APP_CORPORATE_MONTHLY_V2;
      break;
    case "corporateYearlyV2":
      productId = process.env.REACT_APP_CORPORATE_YEARLY_V2;
      break;
    default:
  }
  return productId;
};
