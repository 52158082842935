//! Ant Imports

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Layout from "antd/lib/layout";

const VERSION = require("../../../../package.json").version;
const { Footer } = Layout;

const AppFooter = () => {
  const year = new Date().getFullYear();
  return (
    <Footer className="app-footer-wrapper">
      <Row className="footer-row">
        <Col xs={24} md={6} lg={2} className="app-version">
          v{VERSION}
        </Col>
        <Col xs={24} md={6} lg={13}>
          © {year}
        </Col>
        <Col xs={24} md={6} lg={3}>
          <a
            href="https://contactbook.app/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
        </Col>
        <Col xs={24} md={6} lg={3}>
          <a
            href="https://contactbook.app/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Col>
        <Col xs={24} md={6} lg={3}>
          <a
            href="https://forms.gle/stnXHqLwStHa14r19"
            target="_blank"
            rel="noreferrer"
          >
            Got Feedback?
          </a>
        </Col>
      </Row>
    </Footer>
  );
};
export default AppFooter;
