import { useState } from "react";

//! Ant Imports

import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

//! User Files

import Manage from "assets/images/onboarding/manage.jpg";
import SyncWithCB from "assets/images/onboarding/sync-with-cb.jpg";
import Share from "assets/images/onboarding/share.jpg";
import AutoSync from "assets/images/onboarding/auto-sync.jpg";
import SchedulerContent from "modules/scheduler/components/SchedulerContent";

function OnboardingSlidesModal({
  onboardingModalVisible,
  handleOnboardingDone,
}) {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      image: Manage,
      title: "Organize your contacts for sharing",
      content:
        "In Google Contacts, assign appropriate labels to the contacts you want to share!",
    },
    {
      image: SyncWithCB,
      title: "Sync your account from ContactBook",
      content:
        "Press sync button on right top. Upon successful sync, labels and contacts will appear on dashboard",
    },
    {
      image: Share,
      title: "Share contacts with your colleagues, family and friends",
      content:
        "Share contacts with your domain users or any other Gmail users and they will get instant access to contacts",
    },
    {
      image: AutoSync,
      title: "Automatic sync across multiple devices",
      content:
        "Shared contacts will be automatically accessible in all devices of users to whom they are shared",
    },
    {
      timeGetter: (
        <SchedulerContent
          prev={prev}
          fromDashboard
          handleCancel={handleOnboardingDone}
        />
      ),
    },
  ];

  return (
    <Modal
      className="cb-onboarding-modal"
      keyboard={false}
      maskClosable={false}
      closable={false}
      centered
      visible={onboardingModalVisible}
      onCancel={handleOnboardingDone}
      footer={null}
      width={800}
    >
      <div className="steps-content-wrapper">
        {steps[current].image && (
          <div className="step-img">
            <img src={steps[current].image} alt={steps[current].title} />
          </div>
        )}
        {steps[current].title && (
          <div className="step-title">
            <Title className="cb-text-center" level={4}>
              {steps[current].title}
            </Title>
          </div>
        )}
        {steps[current].content && (
          <div className="step-content">
            <Text className="cb-text-center" type="secondary">
              {steps[current].content}
            </Text>
          </div>
        )}
        {steps[current].timeGetter && steps[current].timeGetter}
      </div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={next}>
            Next
          </Button>
        )}
        {current > 0 && current < steps.length - 1 && (
          <Button onClick={prev}>Previous</Button>
        )}
      </div>
    </Modal>
  );
}

export default OnboardingSlidesModal;
