//! Ant Imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";

//! User Files

// import { AppContext } from "AppContext";
import SubscribedUsers from "./components/SubscribedUsers";
import PlanDetails from "./components/PlanDetails";
// import DeleteAccount from "./components/DeleteAccount";

const Account = () => {
  //!Will remove the comments once tested
  // const {
  //   state: { currentUser },
  // } = useContext(AppContext);

  // if (!currentUser?.isConsentGiven) return <Redirect to="/" />;
  return (
    <div className="account cb-height-inherit">
      <Row className="account-row cb-scrollbar">
        <Col className="account-col subscribed-user-wrapper" xs={24} lg={24}>
          <SubscribedUsers />
        </Col>
        <Col className="account-col plan-details-wrapper" xs={24} lg={24}>
          <PlanDetails />
        </Col>
        {/* <Col className="account-col delete-account-wrapper" xs={24} lg={12}>
          <DeleteAccount />
        </Col> */}
      </Row>
    </div>
  );
};

export default Account;
