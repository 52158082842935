import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_DOMAIN_USERS_LIST = gql`
  query getDomainUserList {
    getDomainUserList {
      data
      statusCode
      statusMessage
    }
  }
`;
