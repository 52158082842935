export const SET_ONBOARDING_DONE = "SET_ONBOARDING_DONE";
export const SET_TOKEN = "SET_TOKEN";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_ID = "SET_USER_ID";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const LOGOUT = "LOGOUT";

export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";

export const SET_DOMAIN_USERS = "SET_DOMAIN_USERS";
export const SET_DOMAIN_USERS_FETCH_ERROR = "SET_DOMAIN_USERS_FETCH_ERROR";

export const SET_SYNC_STATUS = "SET_SYNC_STATUS";
export const SET_SYNC_STATUS_FETCH_ERROR = "SET_SYNC_STATUS_FETCH_ERROR";

export const SET_CONSENT_GIVEN = "SET_CONSENT_GIVEN";
export const SET_MIGRATION_STATUS = "SET_MIGRATION_STATUS";

export const SET_DOMAIN_PLAN = "SET_DOMAIN_PLAN";
export const SET_DOMAIN_SHARED_USERS = "SET_DOMAIN_SHARED_USERS";

export const SET_AUTO_SYNC_PREFERENCE = "SET_AUTO_SYNC_PREFERENCE";

export const SET_DELEGATION_FLAG = "SET_DELEGATION_FLAG";

export const SET_USER_RIGHTS = "SET_USER_RIGHTS";