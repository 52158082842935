import { useContext } from "react";

//! Ant Imports

import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

//! User Files

import { AppContext } from "AppContext";
import { MIGRATION_STATUS } from "common/messages/migrationStatus";
import MigrationText from "./components/MigrationText";

function MigrationModal({ migrationModalState, handleMigration }) {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { MIGRATION_STATUS_IN_PROGRESS } = MIGRATION_STATUS;

  const { visible, confirmLoading } = migrationModalState;
  const buttonText = confirmLoading ? "Migrating" : "Migrate";

  const isMigrationInProgress =
    currentUser?.isOldUser &&
    currentUser.migrationStatus === MIGRATION_STATUS_IN_PROGRESS;

  const renderFooter = (
    <>
      <Text type="warning" className="cb-text-strong">
        {isMigrationInProgress && "Migration is in progress"}
      </Text>
      <Button
        type="primary"
        loading={confirmLoading}
        disabled={isMigrationInProgress}
        onClick={handleMigration}
      >
        {buttonText}
      </Button>
    </>
  );

  const title = (
    <Title level={4} className="cb-text-strong">
      Migrate to new version of Contactbook
    </Title>
  );

  return (
    <Modal
      className="cb-migration-modal"
      visible={visible}
      centered
      closable={false}
      destroyOnClose
      keyboard={false}
      maskClosable={false}
      confirmLoading={confirmLoading}
      title={title}
      footer={renderFooter}
      width={600}
    >
      <MigrationText />
    </Modal>
  );
}

export default MigrationModal;
