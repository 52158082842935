//! Ant Imports

import Text from "antd/lib/typography/Text";

//! User Files

import { MIGRATION_TEXT } from "common/migrationText";

function MigrationText() {
  const { start_content, steps, end_content } = MIGRATION_TEXT;
  return (
    <div className="migration-text-wrapper">
      <div className="migration-message">
        <Text>
          Being an existing user of ContactBook, you will have to migrate to our{" "}
          <a
            href="https://contactbook.app/blog/migrating-to-new-version"
            target="_blank"
            rel="noreferrer"
          >
            new version
          </a>
          . Our system will perform certain steps to ensure your smooth
          transition into our new version.
        </Text>
      </div>
      <div className="migration-message">
        <Text className="cb-text-strong">Please read below carefully!</Text>
      </div>
      {start_content.map((content, key) => (
        <div className="migration-message" key={key}>
          <Text type="secondary">{content}</Text>
        </div>
      ))}
      <div className="steps">
        <ul>
          {steps.map((step, key) => (
            <li key={key}>{step}</li>
          ))}
        </ul>
      </div>
      {end_content.map((content, key) => (
        <div className="migration-message" key={key}>
          <Text type="danger">{content}</Text>
        </div>
      ))}
    </div>
  );
}

export default MigrationText;
