import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const GET_SYNC_LOGS = gql`
  query getSyncLogs($offset: Int!, $limit: Int!, $date: String!) {
    getSyncLogs(offset: $offset, limit: $limit, date: $date) {
      data {
        activityType
        activityMessage
        status
        startTime
        endTime
        userIds
        initiatorId
        createdAt
        errorLogs
      }
      statusCode
      statusMessage
    }
  }
`;
