import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@apollo/client";

//! Ant Imports

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";

//! User Files

import { AppContext } from "AppContext";
import DoNotCancelModal from "./DoNotCancelModal";
import { Button } from "antd";

//!Graphql files
import { CANCEL_SUBSCRIPTION } from "../graphql/Mutation";
import { toast } from "common/utils";

function PlanDetails() {
  const {
    state: { planData, currentUser },
  } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [popupType, setPopupType] = useState();
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const handleModalCancel = () => {
    setVisible(false);
  };

  const handleCancel = (type = '') => {
    setVisible(true);
    setPopupType(type);
  };
  const domain = currentUser?.domainName?.split(".")[0];

  const {
    planKey,
    planName,
    userLimit,
    validTill,
    paddleData,
  } = planData;

  const planInfo = [
    {
      key: "Organization",
      value: domain,
    },
    {
      key: "Plan",
      value: planName ?? "-",
    },
    {
      key: "User Limit",
      value: userLimit === null ? "-" : userLimit,
    },
    {
      key: "Valid Till",
      value:
        validTill === "Life Time"
          ? "Life Time"
          : validTill === null ? "-" : moment(parseInt(validTill)).format("Do MMMM, YYYY"),
    },
  ];

  const title = <span className="cb-text-strong">Plan Details</span>;
  const extra = (
    <div className="plan-details-extra">
      <Link to="/pricing">Upgrade</Link>
    </div>
  );

  const renderRow = planInfo.map(({ key, value }, idx) => (
    <Row className="plan-detail-row" key={idx}>
      <Col xs={16} lg={20} className="cb-text-strong cb-font-ellipsis">
        {key}
      </Col>
      <Col xs={8} lg={4} className="cb-font-ellipsis cb-pl1">
        {value}
      </Col>
    </Row>
  ));

  const actions = (
  planKey !== "free" && planKey !== "noplan" && planKey !== null &&
  <Row className="cms-account-action">
    <Col>
      <Button type="primary" danger onClick={() => handleCancel('cancel')}>Cancel Subscription</Button>
    </Col>
  </Row>
  );

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    context: {
      headers: {
        consent: true,
      },
    },
    onCompleted({ cancelPlan }){
      if(cancelPlan?.data?.success) {
        toast({
          message: "Your subscription has been cancelled successfully",
          type: "success"
        })
        setTimeout(() => {
          push('/');
        }, 2000);
      } else {
        toast({
          message: "Something went wrong",
          type: "error"
        })
      }
      setLoading(false);
      setVisible(false);
    },
    onError(error){
      console.log(error);
      setLoading(false);
      setVisible(false);
    }
  })

  const handleUserAction = () => {
    setLoading(true);
    cancelSubscription({ variables:
      { data: { subscriptionId: parseInt(paddleData.subscription_id, 10) }}
    });
  }

  return (
    <>
      <DoNotCancelModal
        visible={visible}
        handleModalCancel={handleModalCancel}
        popupType={popupType}
        handleUserAction={handleUserAction}
        loading={loading}
      />
      <Card
        title={title}
        extra={extra}
        actions={[actions]}
        className={(planKey === "free" || planKey === "noplan" || planKey === null) && "cms-card-action"}
      >
        {renderRow}
      </Card>
    </>
  );
}

export default PlanDetails;
