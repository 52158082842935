import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

//! Ant Imports

import Avatar from "antd/lib/avatar";
import Card from "antd/lib/card";
import Divider from "antd/lib/divider";
import Menu from "antd/lib/menu";
import Popover from "antd/lib/popover";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

//! User Files

import { getCompanyName, getUserStatus } from "common/utils";
import { AppContext } from "AppContext";
import { USER, ADMIN } from "common/constants";
import { roleBasedMenuItems } from "common/menuRoutes";

function UserProfile() {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [clicked, setClicked] = useState(false);

  const userEmail = currentUser?.userEmail;
  const userName = currentUser?.userName;
  const domainName = currentUser?.domainName;
  const isAdmin = currentUser?.isAdmin;
  const isNonAdminGSuiteUser = getUserStatus(domainName, userEmail);

  const handleHide = ({ key }) => {
    setClicked(false);
    history.push(key);
  };

  const handleClick = (visible) => {
    setClicked(visible);
  };

  const USER_ROLE = isAdmin ? ADMIN : isNonAdminGSuiteUser ? ADMIN : USER;

  const content = (
    <>
      <Menu selectable={false}>
        <Menu.Item className="cb-user-info">
          <Title level={5}>{userName}</Title>
          <Text type="secondary" ellipsis title={userEmail}>
            {userEmail}
          </Text>
        </Menu.Item>
      </Menu>
      <Divider className="cb-divider" />
      <Menu selectedKeys={pathname}>
        {roleBasedMenuItems[USER_ROLE]?.map(({ link, label, icon }) => (
          <Menu.Item
            icon={icon}
            onClick={handleHide}
            key={link}
            className="cb-menu-item"
          >
            {label}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );

  const companyName = getCompanyName(currentUser?.domainName);

  return (
    <Popover
      placement="bottom"
      content={content}
      trigger="click"
      visible={clicked}
      onVisibleChange={handleClick}
    >
      <Card className="app-header-user-profile">
        <div className="app-header-user-avatar">
          <Avatar
            src={currentUser?.profilePicture}
            size={{
              xs: 30,
              sm: 30,
              md: 40,
              lg: 40,
              xl: 40,
              xxl: 40,
            }}
          >
            {currentUser?.userName?.[0]}
          </Avatar>
        </div>
        <div className="app-header-user-data">
          <span className="user-name" title={currentUser?.userName}>
            {currentUser?.userName}
          </span>
          <span className="company-name" title={companyName}>
            {companyName}
          </span>
        </div>
      </Card>
    </Popover>
  );
}

export default UserProfile;
