import React from "react";
//! Ant Imports

import Modal from "antd/lib/modal";
import Button from "antd/lib/button";

const EmptyModal = ({ handleModalPopup }) => {
  let title = <h2>Labels required to share contacts</h2>;
  return (
    <Modal
      className="cb-empty-modal"
      visible={true}
      title={title}
      onCancel={handleModalPopup}
      centered
      footer={null}
      width={520}
    >
      ContactBook syncs with your Google Contacts and helps you share contacts with other users. In order to do that,
      you will have to create labels and associate contacts with them from Google Contacts. You can come back here and
      share those labels with other users.
      <a
        href="https://contacts.google.com/"
        target="_blank"
        rel="noreferrer"
      >
      <Button type="primary">Access Google Contacts</Button>
      </a>
    </Modal>);
}

export default EmptyModal;
