import { Link } from "react-router-dom";

//! Ant Imports

import Result from "antd/lib/result";
import Modal from "antd/lib/modal/Modal";
import Button from "antd/lib/button";
import Text from "antd/lib/typography/Text";

function PaymentSuccessModal({ visible, planDetails, cancelPopup, loading }) {
  const { plan, slug } = planDetails;
  const planPeriod = slug.includes("Yearly") ? "Yearly" : "Monthly";
  const title = (
    <span className="cb-text-strong">
      {`Successfully ${visible ? 'subscribed' : 'unsubscribed' } to ${plan.planName} ${planPeriod}`}
    </span>
  );
  const subtitle = (
    <div className="subtitle-wrapper">
      <Text type="secondary">Plan configuration takes 1-5 minutes</Text>
      <Link to="/">
        <Button type="primary" loading={loading} disabled={loading}>Go to Dashboard</Button>
      </Link>
    </div>
  );
  return (
    visible ?
    <Modal
      width={650}
      centered
      className="cb-payment-success-modal"
      visible={visible}
      maskClosable={false}
      keyboard={false}
      closable={false}
      footer={false}
    >
      <Result
        className="cb-payment-result"
        status="success"
        title={title}
        subTitle={subtitle}
      />
    </Modal> :
    (
      <Modal
        width={650}
        centered
        className="cb-payment-success-modal"
        visible={cancelPopup}
        maskClosable={false}
        keyboard={false}
        closable={false}
        footer={false}
      >
        <Result
          className="cb-payment-result"
          status="success"
          title={title}
          subTitle={subtitle}
        />
      </Modal>
    )
  );
}

export default PaymentSuccessModal;
